import { useState, useEffect } from 'react';
import { useDataProvider, Loading } from 'react-admin';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';


const CityTable = ({startDate, endDate}) => {
    const dataProvider = useDataProvider();
    const [cities, setCities] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    useEffect(() => {
        setLoading(true);
        const query = { id: 'cities', meta: { startDate: startDate, endDate: endDate} };
        dataProvider.getOne('report', query )
            .then(({ data }) => {
                setCities(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, [dataProvider, startDate, endDate]);

    if (loading) return <Loading />;
    if (error) return null;
    if (!cities) return null;

    return( 
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Ville</TableCell>
                        <TableCell align="right">Nombre de clients</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {cities?.top?.map((city) => (
                    <TableRow key={city.name}>
                        <TableCell component="th" scope="row">
                            { city.name === 'undefined' ? 'Ville non fournie' : city.name }
                        </TableCell>
                        <TableCell align="right">{city.clients}</TableCell>
                    </TableRow>
                ))}
                    <TableRow key='total'>
                        <TableCell component="th" scope="row">
                            <Typography variant='h6'>Total cities</Typography>
                        </TableCell>
                        <TableCell align="right"><Typography variant='h6'>{cities?.total}</Typography></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};


export default CityTable;