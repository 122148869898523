import FamilyManage from "./FamilyManage";
import { useGetOne } from "react-admin";

const FamilyUpdate = ({ isOpen, onClose, record }) => {
	const { data, familyLoading } = useGetOne("families", {
		id: typeof record.family === 'number' ? record.family : record.family.data ? record.family.data.id : null,
		meta: { returnRecords: true },
	});

  if( familyLoading || !data?.id ) return null;

	return (
		isOpen ? <FamilyManage
			currentFamily={data.clients.data.map((client) => ({
				id: client.id,
				first_name: client.attributes.first_name,
				last_name: client.attributes.last_name,
			}))}
			isOpen={isOpen}
			onClose={onClose}
		/> : null
	);
};

export default FamilyUpdate;
