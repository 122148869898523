import RequestToggle from "../Fields/RequestToggle";
import { useRecordContext } from "react-admin";
import CircularProgress from "@mui/material/CircularProgress";
import WarningIcon from "@mui/icons-material/Warning";
import Tooltip from "@mui/material/Tooltip";
import useOpenHAT from "../Hooks/useOpenHAT";

const RequestIndicator = (props) => {
	const record = useRecordContext();
	const { data: openHatRequests, isLoading: openHatRequestsLoading } = useOpenHAT({clientId: record?.client?.data?.id});

	if ( !record || openHatRequestsLoading ){
		return (
			<CircularProgress
				sx={{ width: "24px", height: "24px", textAlign: "center" }}
			/>
		);
		}

	return (
		<span
			style={{
				display: "block",
				paddingLeft: props.step === "acknowledged" ? "40px" : "25px",
			}}
		>
			{record?.service?.data?.id === Number( process.env.REACT_APP_HAT_ID ) ? (
				<RequestToggle {...props} />
			) : openHatRequests?.length ? (
				<Tooltip title="Bloqué par référence HAT">
					<WarningIcon
						sx={{
							width: "24px",
							height: "24px",
							margin: "auto",
							opacity: "0.6",
						}}
					/>
				</Tooltip>
			) : (
				<RequestToggle {...props} />
			)}
		</span>
	);
};

export default RequestIndicator;
