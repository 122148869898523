import * as React from "react";
import { Route } from "react-router-dom";
import {
	fetchUtils,
	Admin,
	Resource,
	CustomRoutes,
	defaultTheme,
} from "react-admin";
import { deepmerge } from "@mui/utils";
import authProvider from "./Providers/authProvider";
import Cookies from "./Helpers/cookies";
import simpleRestProvider from "./Providers/ra-strapi-rest";
import { CustomLayout as Layout } from "./Layout/Layout";

//Clients
import ClientList from "./Sections/Clients/ClientList";
import ClientEdit from "./Sections/Clients/ClientEdit";
import ClientCreate from "./Sections/Clients/ClientCreate";
import ClientShow from "./Sections/Clients/ClientShow";

//Requests
import RequestList from "./Sections/Requests/RequestList";

//Reports
import Reports from "./Sections/Reports/Reports";

//Duplicates
import DuplicateList from "./Sections/Duplicates/DuplicateList";
import DuplicateMerge from "./Sections/Duplicates/DuplicateMerge";

//Emails
import { EmailList, EmailEdit, EmailCreate } from "./Sections/Emails/Emails";
import EmailIcon from "@mui/icons-material/Email";

//Segments
import {
	SegmentList,
	SegmentCreate,
	SegmentEdit,
} from "./Sections/Segments/Segments";

//Profile
import Profile from "./Sections/Profile/Profile";

//Form
import FormEdit from "./Sections/Form/FormEdit";

//Suggestions
import {
	SuggestionList,
	SuggestionEdit,
} from "./Sections/Suggestions/Suggestions";

const httpClient = (url, options = {}) => {
	if (!options.headers) {
		options.headers = new Headers({ Accept: "application/json" });
	}
	const token = Cookies.getCookie("token");
	options.headers.set("Authorization", `Bearer ${token}`);
	return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(
	process.env.REACT_APP_API_URL,
	httpClient
);

//Theme settings
const theme = deepmerge(defaultTheme, {
	components: {
		MuiFormControl: { defaultProps: { fullWidth: undefined } },
		MuiTextField: { defaultProps: { fullWidth: undefined } },
		MuiAutocomplete: { defaultProps: { fullWidth: undefined } },
		RaSimpleFormIterator: { defaultProps: { fullWidth: undefined } },
		RaTranslatableInputs: { defaultProps: { fullWidth: undefined } },
	},
});

const App = () => (
	<Admin
		theme={theme}
		layout={Layout}
		dataProvider={dataProvider}
		authProvider={authProvider}
	>
		<Resource
			name="clients"
			list={ClientList}
			edit={ClientEdit}
			create={ClientCreate}
			show={ClientShow}
		/>
		<Resource name="requests" list={RequestList} />
		<Resource name="duplicates" list={DuplicateList} edit={DuplicateMerge} />
		<Resource
			name="emails"
			icon={EmailIcon}
			list={EmailList}
			edit={EmailEdit}
			create={EmailCreate}
		/>
		<Resource
			name="segments"
			list={SegmentList}
			create={SegmentCreate}
			edit={SegmentEdit}
		/>
		<Resource name="suggestions" list={SuggestionList} edit={SuggestionEdit} />
		<Resource name="forms" edit={FormEdit} />
		<CustomRoutes>
			<Route path="/reports" element={<Reports />} />
		</CustomRoutes>
		<CustomRoutes>
			<Route path="/profile" element={<Profile />} />
		</CustomRoutes>
	</Admin>
);

export default App;
