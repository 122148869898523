import { useEditContext, Loading, useRefresh } from "react-admin";
import { useState } from "react";
import Panel from "../../Components/Panel";
import FamilyCreate from "../../Components/FamilyCreate";
import FamilyUpdate from "../../Components/FamilyUpdate";
import FamilyList from "../../Components/FamilyList";
import Button from "@mui/material/Button";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupsIcon from '@mui/icons-material/Groups';
import Divider from '@mui/material/Divider';

const ClientFamily = () => {
	const [createOpen, setCreateOpen] = useState(false);
	const [updateOpen, setUpdateOpen] = useState(false);
	const { record, isLoading } = useEditContext();
	const refresh = useRefresh();

	const handleClose = () => {
		setCreateOpen(false);
		setUpdateOpen(false);
		refresh();
	};

	if (isLoading) return <Loading />;

	return (
		<>
			<Panel title="Famille">
				{ record.family && typeof record.family === 'number' ? (
					<>
						<FamilyList family={ record.family }/> 
						<Divider sx={{ margin: "20px 0" }}/>
						<Button onClick={ () => setUpdateOpen(true) } variant="contained" startIcon={ <GroupsIcon /> } sx={{width: "100%"}}>Manage Family</Button>
					</>
					) : (
						<Button onClick={ () =>  setCreateOpen(true) } variant="contained" startIcon={ <GroupAddIcon /> } sx={{width: "100%"}}>Add Family</Button>
				)}
			</Panel>
			<FamilyUpdate isOpen={updateOpen} onClose={handleClose} record={record} />
			<FamilyCreate isOpen={createOpen} onClose={handleClose} />
		</>
	);
};

export default ClientFamily;
