import Box from "@mui/material/Box";
import { DateInput, CheckboxGroupInput, useListContext } from "react-admin";

const RequestTimeframe = ({ label, source }) => {
  const { displayedFilters, setFilters, filterValues } = useListContext();

  //reset filter valuies if not displayed
  if( !displayedFilters[source] ){
    setFilters({...filterValues, [source]: undefined, [`${source}_status`]: undefined });
  };


	return (
		<Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
			<DateInput source={`[${source}][$gte]`} label={`${label} after`} />
			<DateInput source={`[${source}][$lte]`} label={`${label} before`} />
			<CheckboxGroupInput
				label=""
				source={`${source}_status[$ne]`}
				choices={[{ id: "complete", name: "Demandes incomplètes uniquement" }]}
			/>
		</Box>
	);
};

export default RequestTimeframe;
