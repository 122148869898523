import {
	ArrayInput,
	SimpleFormIterator,
	TextInput,
	SelectInput,
} from "react-admin";
import { fieldValidators } from "./fieldValidators";

export function FieldValidations() {
	return (
		<ArrayInput
			source="validations"
			label="Validations"
			fullWidth={true}
			helperText="All validations must be met in order for form to submit."
		>
			<SimpleFormIterator
				inline
				fillWidth={true}
				sx={{ my: 2, p: 2, bgcolor: "#F6F6F6" }}
			>
				<SelectInput
					source={"type"}
					label="Type"
					choices={[
						{ id: "required", name: "Required" },
						{ id: "format", name: "Format" },
					]}
					validate={[fieldValidators.required]}
				/>
				<TextInput source={"formatter"} label="Formatter" validate={[]} />
				<TextInput
					source={"message.fr"}
					label="Message (FR)"
					validate={[fieldValidators.required]}
				/>
				<TextInput
					source={"message.en"}
					label="Message (EN)"
					validate={[fieldValidators.required]}
				/>
			</SimpleFormIterator>
		</ArrayInput>
	);
}
