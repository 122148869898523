import { useState } from "react";
import { useRecordContext } from "react-admin";
import Button from "@mui/material/Button";
import Panel from "../../Components/Panel";
import RevisionView from "../../Components/RevisionView";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Tooltip from "@mui/material/Tooltip";

const ClientRevisions = () => {
	const record = useRecordContext();
	const [revisionOpen, setRevisionOpen] = useState(false);
	const [revision, setRevision] = useState(null);

	return (
		<>
			<Panel title="Révisions">
				{record?.revisions && record.revisions.length
					? record.revisions
							.slice(0)
							.reverse()
							.map((revision, index) => {
								const date = new Date(revision.timestamp);
								return (
									<div key={index}>
										<Button
											onClick={() => {
												setRevisionOpen(true);
												setRevision(revision);
											}}
										>
											{index === 0 ? (
												<Tooltip title="Version actuelle" position="left">
													<ArrowRightIcon sx={{ marginLeft: "-12px", marginRight: "-12px"}}/>
												</Tooltip>
											) : null}
											{`${date.toLocaleString()} (${revision.user})`}
										</Button>
									</div>
								);
							})
					: null}
			</Panel>
			<RevisionView
				isOpen={revisionOpen}
				onClose={() => setRevisionOpen(false)}
				revision={revision}
			/>
		</>
	);
};

export default ClientRevisions;
