import {
	ArrayInput,
	SimpleFormIterator,
	TextInput,
	SelectInput,
} from "react-admin";
import { fieldValidators } from "./fieldValidators";

export function FieldConditions() {
	return (
		<ArrayInput
			source="condition_groups"
			label="Conditions Groups"
			fullWidth={true}
			helperText="Condition Groups are evaluated using OR logic, meaning at least one condition group must evaluate as true in order for the field to appear."
		>
			<SimpleFormIterator
				inline
				fillWidth={true}
				sx={{ my: 2, p: 2, bgcolor: "#F6F6F6" }}
			>
				<ArrayInput
					source={"conditions"}
					label="Conditions"
					fullWidth={true}
					helperText="Conditions are evaluated using AND logic, meaning all conditions within the current group must evaluate as true in order for the condition group to be true."
				>
					<SimpleFormIterator
						inline
						fillWidth={true}
						sx={{ my: 2, p: 2, bgcolor: "#F6F6F6" }}
					>
						<TextInput
							source={"field"}
							label="Field Name"
							validate={[fieldValidators.required, fieldValidators.isSlug]}
						/>
						<TextInput
							source={"step"}
							label="Step"
							validate={[fieldValidators.required, fieldValidators.isSlug]}
						/>
						<SelectInput
							source={"operator"}
							label="Operator"
							choices={[
								{ id: "=", name: "equals" },
								{ id: "!=", name: "does not equal" },
								{ id: "includes", name: "includes" },
								{ id: "!includes", name: "does not include" },
								{ id: "gt", name: "greater than" },
								{ id: "gte", name: "greater than or equal" },
								{ id: "lt", name: "less than" },
								{ id: "lte", name: "less than or equal" },
							]}
							defaultValue={"="}
							validate={[]}
						/>
						<TextInput
							source={"value"}
							label="Value"
							validate={[fieldValidators.required]}
						/>
					</SimpleFormIterator>
				</ArrayInput>
			</SimpleFormIterator>
		</ArrayInput>
	);
}
