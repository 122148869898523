import { useRecordContext, useGetOne, useList, ListContextProvider, Datagrid, TextField, DateField, EditButton } from 'react-admin';
import ClientNameField from '../Fields/ClientName';

const RelatedClients = ({ endpoint }) => {
  const record = useRecordContext();
  
  const id = endpoint === 'families' ? record.family.data.id : record.id;
  
  const { data, isLoading } = useGetOne( endpoint, { id, meta: {'returnRecords': true } } );
  
  const clients = data?.clients?.data?.map(client => ({ id: client.id, ...client.attributes })).filter( client => client.id !== record.id );
  const listContext = useList({ data: clients ?? null, isLoading });

  return (
    <ListContextProvider value={listContext}>
      <Datagrid isRowExpandable={ record => true } expand={<></>} isRowSelectable={ record => true } sx={{
        '& .RaDatagrid-tableWrapper': {
          marginBottom: '-1px',
          backgroundColor: '#f9f9f9',
        },
        '& .RaDatagrid-thead': {
          display: 'none',
        },
        '& .RaDatagrid-expandIconCell': {
          opacity: '0',
          pointerEvents: 'none',
        },
        '& .MuiTableCell-paddingCheckbox': {
          opacity: '0',
          pointerEvents: 'none',
        },
        //TODO: this level of per column styling is silly but the only way to get everything aligned. Will need to adjust as we add more columns
        '& .RaDatagrid-rowCell.column-last_name ': {
          paddingRight: '22px'
        },
        '& .RaDatagrid-rowCell.column-email ': {
          paddingRight: '26px'
        },
      }}>
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
        <ClientNameField label="Name" source="last_name" />
        <TextField source="email" />
        { endpoint === 'duplicate' ? <></> : <EditButton label="Details" /> }
      </Datagrid>
    </ListContextProvider>
  )
}

export default RelatedClients;