import Grid from "@mui/material/Grid";
import {
	ArrayInput,
	SimpleFormIterator,
	FormDataConsumer,
	TextInput,
	SelectInput,
	BooleanInput,
} from "react-admin";
import { fieldValidators } from "./field-helpers/fieldValidators";
import { FieldTypes } from "./field-helpers/FieldTypes";
import { FieldConditions } from "./field-helpers/FieldConditions";
import { FieldChoices } from "./field-helpers/FieldChoices";
import { FieldValidations } from "./field-helpers/FieldValidations";
import { RichTextInput } from "ra-input-rich-text";

//input types for non-standard fields
const fieldtypes = {
	boolean: "checkbox",
	cards: "radio",
	hidden: "hidden",
	date: "date",
	country: "select",
};

export default function Fields({ title, source, disableManagement = false }) {
	return (
		<>
			{title ? (
				<Grid item xs={12}>
					<h3>{title}</h3>
				</Grid>
			) : null}
			<Grid item xs={12}>
				<ArrayInput source={source} label="Fields">
					<SimpleFormIterator
						fullWidth
						inline
						sx={{
							borderBlockEnd: "solid 1px #E0E0E0",
							".RaSimpleFormIterator-form": { flexWrap: "wrap" },
						}}
						disableAdd={disableManagement}
						disableRemove={disableManagement}
						disableReordering={disableManagement}
						disableClear={disableManagement}
					>
						<FormDataConsumer>
							{({ scopedFormData, ...rest }) => (
								<>
									<TextInput
										source="name"
										label="Name"
										validate={[
											fieldValidators.required,
											fieldValidators.isSlug,
										]}
									/>
									{scopedFormData &&
									scopedFormData.field !== "message" &&
									scopedFormData.field !== "hidden" ? (
										<TextInput
											source="label.fr"
											label="Label (FR)"
											validate={[fieldValidators.required]}
										/>
									) : null}
									{scopedFormData &&
									scopedFormData.field !== "message" &&
									scopedFormData.field !== "hidden" ? (
										<TextInput
											source="label.en"
											label="Label (EN)"
											validate={[fieldValidators.required]}
										/>
									) : null}
									{scopedFormData &&
									scopedFormData.field !== "message" &&
									scopedFormData.field !== "hidden" ? (
										<TextInput
											source="placeholder.fr"
											label="Placeholder (FR)"
											validate={[]}
										/>
									) : null}
									{scopedFormData &&
									scopedFormData.field !== "message" &&
									scopedFormData.field !== "hidden" ? (
										<TextInput
											source="placeholder.en"
											label="Placeholder (EN)"
											validate={[]}
										/>
									) : null}
									<SelectInput
										source="field"
										label="Field"
										choices={[
											{ id: "boolean", name: "True/False" },
											{ id: "cards", name: "Cards" },
											{ id: "choice", name: "Choice" },
											{ id: "date", name: "Date" },
											{ id: "input", name: "Input" },
											{ id: "select", name: "Select" },
											{ id: "textarea", name: "Textarea" },
											{ id: "message", name: "Message" },
											{ id: "country", name: "Country" },
											{ id: "hidden", name: "Hidden" },
										]}
										validate={[fieldValidators.required]}
									/>

									{scopedFormData && scopedFormData.field === "input" && (
										<FieldTypes />
									)}

									{scopedFormData && scopedFormData.field === "message" ? (
										<SelectInput
											source="type"
											label="Message Type"
											choices={[
												{ id: "info", name: "Information" },
												{ id: "warning", name: "Warning" },
											]}
											validate={[fieldValidators.required]}
											defaultValue={"info"}
										/>
									) : (
										<TextInput
											style={{ display: "none" }}
											source="type"
											defaultValue={fieldtypes[scopedFormData?.field] || "text"}
										/>
									)}

									{scopedFormData && scopedFormData.field === "choice" ? (
										<SelectInput
											source="type"
											label="Type"
											choices={[
												{ id: "radio", name: "Radio" },
												{ id: "checkbox", name: "Checkbox" },
											]}
											defaultValue={"radio"}
										/>
									) : null}

									<BooleanInput source="has_conditions" label="Conditions" />

									{scopedFormData &&
									scopedFormData.field !== "message" &&
									scopedFormData.field !== "hidden" ? (
										<BooleanInput
											source="has_validations"
											label="Validations"
										/>
									) : null}

									{scopedFormData &&
									scopedFormData.field !== "message" &&
									scopedFormData.field !== "hidden" &&
									scopedFormData.field !== "cards" &&
									scopedFormData.field !== "textarea" ? (
										<BooleanInput source="narrow" label="Narrow?" />
									) : null}

									{scopedFormData &&
									(scopedFormData.field === "choice" ||
										scopedFormData.field === "select" ||
										scopedFormData.field === "cards") ? (
										<FieldChoices isCards={scopedFormData.field === "cards"} />
									) : null}

									{scopedFormData && scopedFormData.field === "message" ? (
										<RichTextInput
											source="message.fr"
											label="Message (FR)"
											validate={[fieldValidators.required]}
										/>
									) : null}

									{scopedFormData && scopedFormData.field === "message" ? (
										<RichTextInput
											source="message.en"
											label="Message (EN)"
											validate={[fieldValidators.required]}
										/>
									) : null}

									{scopedFormData && scopedFormData.has_conditions === true ? (
										<FieldConditions />
									) : null}
									{scopedFormData && scopedFormData.has_validations === true ? (
										<FieldValidations />
									) : null}
								</>
							)}
						</FormDataConsumer>
					</SimpleFormIterator>
				</ArrayInput>
			</Grid>
		</>
	);
}
