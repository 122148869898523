import { useState, useEffect } from 'react';
import { useDataProvider, Loading } from 'react-admin';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

const NewTable = ({startDate, endDate}) => { 
    const dataProvider = useDataProvider();
    const [new_vs_returning, setNew_vs_returning] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    useEffect(() => {
        setLoading(true);
        const query = { id: 'new_vs_returning', meta: { startDate: startDate, endDate: endDate} };
        dataProvider.getOne('report', query )
            .then(({ data }) => {
                setNew_vs_returning(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
        }, [dataProvider, startDate, endDate]);

    if (loading) return <Loading />;
    if (error) return null;
    if (!new_vs_returning) return null;
    return(
        <TableContainer>
            <Table size="small">
                <TableBody>
                    <TableRow><TableCell component="th" scope="row">New</TableCell><TableCell align="right">{new_vs_returning.new}</TableCell></TableRow>
                    <TableRow><TableCell component="th" scope="row">Returning</TableCell><TableCell align="right">{new_vs_returning.returning}</TableCell></TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}


export default NewTable;