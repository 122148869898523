import { useReducer } from "react";
import RemoveIcon from "@mui/icons-material/RemoveCircle";
import AddIcon from "@mui/icons-material/AddCircle";
import SegmentFilterGroup from "./SegmentFilterGroup";
import { useRecordContext, TextInput } from "react-admin";
import { Box, Button, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";

const SegmentFilters = () => {
  const { setValue } = useFormContext();
  const record = useRecordContext();
  const [ filters, updateFilters ] = useReducer( (filters, action) => {
    //Add a new filter group
    if (action.type === 'addGroup') {
      console.log('add group');
      return [...filters, []];
    }

    //Remove a filter group
    if( action.type === 'removeGroup' ) {
      console.log('remove group', action.index);
      return filters.filter((_, index) => index !== action.index);
    }

    //Update a filter group
    if( action.type === 'updateGroup' ) {
      console.log('update group', action.index, action.filterGroup);
      return filters.map((filterGroup, index) => index === action.index ? action.filterGroup : filterGroup);
    }

    //Add a filter to a filter group
    if( action.type === 'addFilter' ) {
      console.log('add filter');
      return filters.map((filterGroup, index) => index === action.index ? [...filterGroup, {}] : filterGroup);
    }

    //Remove a filter from a filter group
    if( action.type === 'removeFilter' ) {
      console.log('remove filter', action.index, action.filterIndex);
      return filters.map((filterGroup, index) => index === action.index ? filterGroup.filter((_, filterIndex) => filterIndex !== action.filterIndex) : filterGroup);
    }

    //Update a filter in a filter group
    if( action.type === 'updateFilter' ) {
      console.log('update filter', action.index, action.filterIndex, action.filter);
      return filters.map((filterGroup, index) => index === action.index ? filterGroup.map((filter, filterIndex) => filterIndex === action.filterIndex ? action.filter : filter) : filterGroup);
    }

    setValue("updatedFilters", JSON.stringify(filters));

    return filters;
  }, record?.filters || [] );

  // This is all very dumb. We're converting JSON from the API into managable form objects below 
  // and then converting back into a strong in this field, which is what actually gets passed 
  // back to the API and stored as the new set of filters
  
  setValue("updatedFilters", JSON.stringify(filters));

  return (
    <>
      <Box sx={{display: 'none' }}><TextInput source="updatedFilters" /></Box>
      { filters.map((filterGroup, filterGroupIndex) => {
        return (
          <Box key={filterGroupIndex}>
            {filterGroupIndex === 0 ? (
              <Typography>Include clients if:</Typography>
              ) : (
                <Typography>- or - </Typography>
                )}
            <Box
              key={filterGroupIndex}
              display="flex"
              m="1em 0"
              p="1em 1em 0em"
              bgcolor="rgba(0,0,0,0.05)"
            >
              <SegmentFilterGroup
                key={filterGroupIndex}
                filterGroup={filterGroup}
                filterGroupIndex={filterGroupIndex}
                updateFilters={updateFilters}
              />
              <Box p="0em 1em 1em" flex="1" alignSelf="center">
                <Button
                  label="Remove"
                  type="button"
                  onClick={() => updateFilters({ type: 'removeGroup', index: filterGroupIndex })}
                >
                  <RemoveIcon />
                </Button>
              </Box>
            </Box>
          </Box>
        );
      })}
      <Button
        label="Add Filter Group"
        type="button"
        onClick={() => updateFilters({ type: 'addGroup' })}
      >
        <AddIcon />
      </Button>
    </>
  )
};

export default SegmentFilters;