import { useGetOne, EditButton, useEditContext } from "react-admin";
import CircularProgress from '@mui/material/CircularProgress';


const FamilyList = ({ family }) => {
	const { record, isLoading } = useEditContext();
  const { data, familyLoading } = useGetOne("families", {
		id: family,
    meta: { returnRecords: true },
	});

	if (isLoading || familyLoading || !data ) return <CircularProgress />;

  return (
  <>
    {data.clients?.data?.map((familyMember) => {
      return familyMember.id !== record.id ? (
        <EditButton
          key={familyMember.id}
          resource="clients"
          label={
            familyMember.attributes.first_name +
            " " +
            familyMember.attributes.last_name
          }
          record={{ id: familyMember.id }}
          icon={null}
        />
      ) : null;
    })}
  </>
  );
}

export default FamilyList;