
import { List, Datagrid, TextField, DateField, EditButton } from 'react-admin';
import ClientNameField from '../../Fields/ClientName';
import RelatedClients from '../../Components/RelatedClients';
import CallMergeIcon from '@mui/icons-material/CallMerge';

const EmptyPagination = () => null;

const DuplicateList = (props) => ( 
  <List sort={{ field: 'updatedAt', order: 'DESC' }} pagination={<EmptyPagination />} {...props}>
    <Datagrid isRowExpandable={() => true } expand={<RelatedClients endpoint="duplicates" />} sx={
      {
        '& .RaDatagrid-expandedPanel > .MuiTableCell-body': {
          padding: '0px',
        },
      }
    }>
      <DateField label="Created" source="createdAt" showTime />
      <DateField label="Updated" source="updatedAt" showTime />
      <ClientNameField label="Name" source="last_name" />
      <TextField source="email" />
      <EditButton label="Manage" icon={<CallMergeIcon />}  />
    </Datagrid>
  </List>
);

export default DuplicateList;