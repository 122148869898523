import { useGetIdentity } from "react-admin";
import { Loading } from "react-admin";
import Card from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
	Form,
	TextInput,
	PasswordInput,
	SaveButton,
	useAuthProvider,
	useNotify,
} from "react-admin";

const validatePasswords = (values) => {
	const errors = {};
	if (values.password !== values.passwordConfirmation) {
		errors.password = ["Les mots de passe ne correspondent pas"];
		errors.passwordConfirmation = ["Les mots de passe ne correspondent pas"];
	}
	return errors;
};

const Profile = () => {
	const notify = useNotify();
	const { changePassword } = useAuthProvider();
	const { data, isLoading } = useGetIdentity();

	const handleSubmit = async (values) => {
		const { currentPassword, password, passwordConfirmation, ...rest } = values;
		const payload = {
			...rest,
			...(password &&
				passwordConfirmation &&
				currentPassword && { password, currentPassword, passwordConfirmation }),
		};
		// await update('users-permissions', 'me', data);
		changePassword(payload)
			.then((response) => {
				notify("Mot de passe modifié", {type: "success" });
			})
			.catch((error) => {
				notify("Erreur lors de la modification du mot de passe", {type: "warning" });
			});
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={3}></Grid>
			<Grid item xs={6}>
				<h1>Gérer le profil</h1>
				<Card sx={{ padding: 4 }}>
					{isLoading && <Loading />}
					{!isLoading && data && (
						<Form validate={validatePasswords} onSubmit={handleSubmit}>
							<TextInput
								source="username"
								helperText="Cette valeur ne peut être modifiée que par un administrateur"
								defaultValue={data.username}
								disabled
								fullWidth
							/>
							<TextInput
								source="email"
								helperText="Cette valeur ne peut être modifiée que par un administrateur"
								defaultValue={data.email}
								disabled
								fullWidth
							/>
							<h2>Change Password</h2>
							<PasswordInput
								source="currentPassword"
								label="Mot de passe actuel"
								fullWidth
							/>
							<PasswordInput
								source="password"
								label="Nouveau mot de passe"
								fullWidth
							/>
							<PasswordInput
								source="passwordConfirmation"
								label="Confirmer le nouveau mot de passe"
								fullWidth
							/>
							<SaveButton />
						</Form>
					)}
				</Card>
			</Grid>
			<Grid item xs={3}></Grid>
		</Grid>
	);
};

export default Profile;
