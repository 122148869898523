import { useState, useEffect } from 'react';
import { useDataProvider, Loading } from 'react-admin';
import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';

const VulnerabilityTable = ({startDate, endDate}) => {
    const dataProvider = useDataProvider();
    const [vulnerability, setVulnerability] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    useEffect(() => {
        setLoading(true);
        const query = { id: 'vulnerability', meta: { startDate: startDate, endDate: endDate } };
        dataProvider.getOne('report', query )
            .then(({ data }) => {
                setVulnerability(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
        }, [dataProvider, startDate, endDate]);

    if (loading) return <Loading />;
    if (error) return null;
    if (!vulnerability) return null;

    return (
        <TableContainer>
            <Table size="small">
                <TableBody>
                    <TableRow><TableCell component="th" scope="row">Security Concern</TableCell><TableCell align="right">{vulnerability.compromised_safety}</TableCell></TableRow>
                    <TableRow><TableCell component="th" scope="row">Less than 1 year in Canada</TableCell><TableCell align="right">{vulnerability.time_in_canada}</TableCell></TableRow>
                    <TableRow><TableCell component="th" scope="row">More than 3 services requested</TableCell><TableCell align="right">{vulnerability.service_request_count}</TableCell></TableRow>
                    <TableRow><TableCell component="th" scope="row">No services requested</TableCell><TableCell align="right">{vulnerability.no_services_requested}</TableCell></TableRow>
                    <TableRow><TableCell component="th" scope="row">Referred to HAT by AC Agent</TableCell><TableCell align="right">{vulnerability.agent_referral}</TableCell></TableRow>
                    <TableRow><TableCell component="th" scope="row"><Typography variant="h6">Total HAT Referrals</Typography></TableCell><TableCell align="right"><Typography variant="h6">{vulnerability.vulnerable}</Typography></TableCell></TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
};


export default VulnerabilityTable;