import { Loading, useGetList } from "react-admin";
import { SelectInput } from "react-admin";

const SegmentsInput = () => {
	const { data: segments, segmentsLoading } = useGetList(
		"segments",
		{
			pagination: { page: 1, perPage: 1000 },
		}
	);

	if (segmentsLoading || !segments) {
		return <Loading />;
	}

	return (
		<SelectInput
			source="segment"
			label="Segment"
			choices={segments}
			optionText="name"
			optionValue="id"
		/>
	);
};

export default SegmentsInput;
