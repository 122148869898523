import { useState, useEffect, React } from 'react';
import { useDataProvider, Loading } from 'react-admin';
import { Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const RequestTable = ({startDate, endDate}) => { 
    const dataProvider = useDataProvider();
    const [requests, setRequests] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    useEffect(() => {
        setLoading(true);
        const query = { id: 'requests', meta: { startDate: startDate, endDate: endDate} };
        dataProvider.getOne('report', query )
            .then(({ data }) => {
                setRequests(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
        }, [dataProvider, startDate, endDate]);

    if (loading) return <Loading />;
    if (error) return null;
    if (!requests) return null;

    return(
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Service Group</TableCell>
                        <TableCell>Requested</TableCell>
                        <TableCell>Referred</TableCell>
                        <TableCell>Acknowledged</TableCell>
                        <TableCell>Completed</TableCell>
                        <TableCell>Time</TableCell>
                        <TableCell align="right">Yield</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {requests.service_groups.map((service_group) => (
                    <Row key={service_group.name} row={service_group} />
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
};

const DateString = (time) => {
    const seconds = parseInt(time, 10);
    const days    = Math.floor(seconds / 86400);
    const hours   = Math.floor((seconds - (days * 86400)) / 3600);
    const minutes = Math.floor((seconds - (days * 86400) - (hours * 3600)) / 60);
    return `${days} days ${hours} hrs ${minutes} min`;
}

const Row = (props) => {
    const { row } = props;
    const [open, setOpen] = useState(false);
    return (
        <>
            <TableRow>
                <TableCell style={{width: '30px', fontWeight: 'bold', borderBottom: 0, paddingLeft: 0, paddingRight: 0}}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" style={{ width: 'calc(30% - 30px)', fontWeight: 'normal', borderBottom: 0}}>{row.name}</TableCell>
                <TableCell align="center" style={{ width: '10%', fontWeight: 'normal', borderBottom: 0}}>{row.requested}</TableCell>
                <TableCell align="center" style={{ width: '10%', fontWeight: 'normal', borderBottom: 0}}>{row.referred}</TableCell>
                <TableCell align="center" style={{ width: '10%', fontWeight: 'normal', borderBottom: 0}}>{row.acknowledged}</TableCell>
                <TableCell align="center" style={{ width: '10%', fontWeight: 'normal', borderBottom: 0}}>{row.received}</TableCell>
                <TableCell align="center" style={{ width: '20%', fontWeight: 'normal', borderBottom: 0}}>{ DateString( Math.ceil( row.totalTime / row.requested ) ) }</TableCell>
                <TableCell align="right" style={{ width: '10%', fontWeight: 'normal', borderBottom: 0}}>{ Math.floor( row.received / row.requested * 100 ) + '%'}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Table size="small" aria-label="services" style={{marginBottom: -1}}>
                            <TableBody>
                            {row.services.map((service) => (
                                <TableRow key={service.name}>
                                    <TableCell style={{width: '5px' }}></TableCell>
                                    <TableCell component="th" scope="row" style={{ width: 'calc(30% - 5px)' }}>{service.name}</TableCell>
                                    <TableCell align="center" style={{ width: '10%' }}>{service.requested}</TableCell>
                                    <TableCell align="center" style={{ width: '10%' }}>{service.referred}</TableCell>
                                    <TableCell align="center" style={{ width: '10%' }}>{service.acknowledged}</TableCell>
                                    <TableCell align="center" style={{ width: '10%' }}>{service.received}</TableCell>
                                    <TableCell align="center" style={{ width: '20%' }}>{ DateString( Math.ceil( service.totalTime / service.requested ) ) }</TableCell>
                                    <TableCell align="right" style={{ width: '10%' }}>{ Math.floor( service.received / service.requested * 100 ) + '%' }</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}


export default RequestTable;