import {
	ArrayInput,
	SimpleFormIterator,
	TextInput,
	SelectInput,
} from "react-admin";
import { fieldValidators } from "./fieldValidators";

export function FieldChoices({ isCards }) {
	return (
		<ArrayInput source="options" label="Choices" fullWidth={true}>
			<SimpleFormIterator
				inline
				sx={{ my: 2, p: 2, bgcolor: "#F6F6F6", width: "auto" }}
			>
				<TextInput
					source={"value"}
					label="Value"
					validate={[fieldValidators.required]}
				/>
				<TextInput
					source={"label.fr"}
					label="Label (FR)"
					validate={[fieldValidators.required]}
				/>
				<TextInput
					source={"label.en"}
					label="Label (EN)"
					validate={[fieldValidators.required]}
				/>
				{isCards && (
					<SelectInput
						source={"icon"}
						label="Icon"
						choices={[
							{ id: "provider", name: "Provider" },
							{ id: "client", name: "Client" },
							{ id: "employee", name: "Employee" },
						]}
					/>
				)}
			</SimpleFormIterator>
		</ArrayInput>
	);
}
