import * as React from "react";
import { Datagrid, List, TextField, EditButton } from "react-admin";

export const SuggestionList = (props) => (
	<List sort={{ field: "updatedAt", order: "DESC" }} {...props}>
		<Datagrid>
			<TextField source="organization" label="Organization" />
			<TextField source="service_type" label="Type" />
			<TextField source="contact_name" label="Contact" />
			<TextField source="status" label="Status" />
			<EditButton />
		</Datagrid>
	</List>
);

export default SuggestionList;
