import * as React from "react";
import { useState } from "react";
import {
	useRecordContext,
	IconButtonWithTooltip,
	LoadingIndicator,
	useGetIdentity,
} from "react-admin";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import EditIcon from "@mui/icons-material/Edit";
import PendingIcon from "@mui/icons-material/Pending";
import PhoneDisabledIcon from "@mui/icons-material/PhoneDisabled";
import EmailIcon from "@mui/icons-material/Email";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import ReceivedStatus from "../Components/ReceivedStatus";

const RequestToggle = ({ step, updateRequest }) => {
	const record = useRecordContext();
	const { identity, identityLoading } = useGetIdentity();

	//Step status popoover
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	//Received status dialog
	const [dialogOpen, setDialogOpen] = useState(false);

	const [stepStatus, setStepStatus] = useState({
		status: record[`${step}_status`],
		date: record[step],
		activity: record[`${step}_activity`],
		user: record[`${step}_user`],
		reasons: record[`${step}_reasons`],
	});

	//Return if record is not loaded
	if (!record || identityLoading) return <LoadingIndicator />;

	//handle status updates
	const updateStatus = (newStatus, stepToUpdate = step) => {
		setStepStatus({ ...newStatus });

		const newRequest = {
			...Object.keys(newStatus).reduce((acc, key) => {
				if( key === "date" ){
					acc[`${stepToUpdate}`] = newStatus[key];
				}else{
					acc[`${stepToUpdate}_${key}`] = newStatus[key];
				}
				return acc;
			}, {}),
		};

		updateRequest(record.id, newRequest);
	};

	let canUpdate = true;
	if (step === "received" && record["acknowledged_status"] !== "complete")
		canUpdate = false;
	// if (step === "acknowledged" && record["referred_status"] !== "complete")
	// 	canUpdate = false;
	if (step === "referred" && record["requested_status"] !== "complete")
		canUpdate = false;

	const statusIcon = {
		complete: <CheckCircleIcon color="green" />,
		incomplete: <RadioButtonUncheckedIcon color="inherit" />,
		pending: <PendingIcon color="inherit" />,
    not_reachable: <PhoneDisabledIcon  color="orange" />,
		message_left: <EmailIcon  color="grey" />,
		not_interested: <NotInterestedIcon  color="red" />,
		other: <CheckCircleIcon  color="green" />,
	};

	const labels = {
		requested: "Request",
		referred: "Refer",
		acknowledged: "Acknowledge",
		received: "Complete",
	};

	const label =
		!stepStatus.status
			? labels[step]
			: "Details";

	return (
		<span style={{ display: "block", marginLeft: "-7.5px"}}>
			<IconButtonWithTooltip
				aria-describedby={id}
				label={label}
				onClick={(event) => {
					stepStatus.status
						? setAnchorEl(event.currentTarget)
						: updateStatus({
								status: step === "received" ? "pending" : "complete",
								date: new Date().toISOString(),
								user: identity.username,
								activity: `dashboard_${step}`,
						});
				}}
				disabled={!canUpdate}
				color={canUpdate ? "primary" : "inherit"}
			>
				{ statusIcon[stepStatus.status] ?? ( stepStatus.status ? statusIcon["other"] : statusIcon["incomplete"] ) }
			</IconButtonWithTooltip>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<Typography sx={{ p: 2 }}>
					<b>{"Date: "}</b> {stepStatus.date}
					<br />
					{step === "received" ? (
						<>
							<b>{"Status: "}</b> {stepStatus.status}{" "}
							{
								<IconButtonWithTooltip
									label="Edit"
									onClick={() => setDialogOpen(!dialogOpen)}
								>
									<EditIcon />
								</IconButtonWithTooltip>
							}
							<br />
						</>
					) : null}
					<b>{"User: "}</b> {stepStatus.user}
					<br />
					<b>{"Action: "}</b> {stepStatus.activity}
					{ step === "requested" && stepStatus.reasons ? 
					<>
						<br />
						<b>{"Reasons: "}</b> {stepStatus.reasons.map((reason, index) => {
							return <span key={index}>{reason}{index < stepStatus.reasons.length - 1 ? ", " : ""}</span>
							})}
					</>
					: null }
				</Typography>
			</Popover>
			{step === "received" ? (
				<ReceivedStatus
					isOpen={dialogOpen}
					closeDialog={() => setDialogOpen(false)}
          updateStatus={updateStatus}
				/>
			) : null}
		</span>
	);
};

export default RequestToggle;
