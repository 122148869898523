import Grid from "@mui/material/Grid";
import Fields from "./Fields";
import Typography from "@mui/material/Typography";

export default function FormPeopleInformation() {
	const section = "people";

	return (
		<Grid container spacing={2} sx={{ width: "100%" }}>
			<Grid item xs={12}>
				<h2>{`People > Contact Information`}</h2>
			</Grid>
			<Grid item xs={12}>
				<h3>Fields</h3>
				<Typography sx={{ width: "33%", flexShrink: 0 }}>
					Contact Information
				</Typography>
				<Typography sx={{ color: "text.secondary" }}>
					Step: 'people.information'
				</Typography>
				<Fields title="" source={`${section}.information`} />
			</Grid>
		</Grid>
	);
}
