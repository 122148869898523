import {
	Edit,
	useNotify,
	useRefresh,
	useRedirect,
	useGetRecordId,
	useRecordContext,
	TopToolbar,
	ShowButton,
	ListButton,
} from "react-admin";
import ClientForm from "./ClientForm";
import ClientEditAside from "./ClientEditAside";

const ClientTitle = () => {
	const record = useRecordContext();
	return <span>Client: {record ? `${record.first_name} ${record.last_name}` : ''}</span>;
}

const ClientEditActions = () => (
	<TopToolbar>
			<ShowButton />
			<ListButton />
	</TopToolbar>
);

const ClientEdit = () => {
	const recordId = useGetRecordId();
	const notify = useNotify();
	const refresh = useRefresh();
	const redirect = useRedirect();

	const onSuccess = () => {
    //Get current tab from last directory of url
    const currentTab = window.location.hash.split('/').pop();
    let redirectUrl = `/clients/${recordId}`;
    if( currentTab.length === 1 ){
      redirectUrl += `/${currentTab}`;
    }
		notify(`Client Updated!`);
		redirect(redirectUrl);
		refresh();
	};

	return (
		<Edit aside={<ClientEditAside />} title={<ClientTitle />} actions={<ClientEditActions />} mutationMode="pessimistic" mutationOptions={{onSuccess}}>
			<ClientForm />
		</Edit>
	);
};

export default ClientEdit;
