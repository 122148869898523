import { useGetOne, Loading, ShowButton } from "react-admin";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";


const Send = ({ send }) => {
	const { data, loading } = useGetOne("sends", { id: send });
	
  if (loading || !data ) return <Loading />;

  const date = new Date(data.createdAt);

	return (
		<Accordion>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography>{`${data.name} [${date.toLocaleString()}]`}</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Box width="100%" display="block">
					<Box display="inline-block" bgcolor="white" p="0 0.5em" m="0 0.5em">
						<Typography variant="h6">Details</Typography>
					</Box>
					<Box m="-1em 0 1em" p="2em 1em" border="solid 1px #efefef">
						<Box p="0.5em 0" display="block">
							Subject : {data.subject}
						</Box>
            <Box p="0.5em 0" display="block">
							Receipients : {data.recipients}
						</Box>
						<Box
							p="0.5em 0"
							display="block"
							dangerouslySetInnerHTML={{ __html: data.content }}
						/>
						{/* <Box width="100%" p="0.5em 0" display="block" >Recipients : { data.recipients }</Box> */}
						<Box p="0.5em 0" display="block">
							<ShowButton
                resource="emails"
                record={{id: data.email}}
								label="View Source Email"
							></ShowButton>
						</Box>
					</Box>
				</Box>
			</AccordionDetails>
		</Accordion>
	);
};

export default Send;
