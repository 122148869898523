import { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {
	useRedirect,
	useRefresh,
	SimpleForm,
	useNotify,
	Edit,
} from "react-admin";
import FormIntroduction from "./FormIntroduction";
import FormPeople from "./FormPeople";
import FormPeopleDetails from "./FormPeopleDetails";
import FormPeopleInformation from "./FormPeopleInformation";
import FormPeopleStatus from "./FormPeopleStatus";
import FormPeopleDemographics from "./FormPeopleDemographics";
import FormPeopleSafety from "./FormPeopleSafety";
import FormServices from "./FormServices";
import FormSuggest from "./FormSuggest";
import FormConfirmation from "./FormConfirmation";

export default function Form() {
	const [activeTab, setActiveTab] = useState("introduction");
	const notify = useNotify();
	const redirect = useRedirect();
	const refresh = useRefresh();

	const onSuccess = (data) => {
		notify(`Changes to Form saved`);
		redirect("/forms/1");
		refresh();
	};

	const handleChange = (event, newValue) => {
		setActiveTab(newValue);
	};

	return (
		<Edit
			title="Form Settings"
			mutationMode="pessimistic"
			mutationOptions={{ onSuccess }}
			transform={(data) => ({
				//Filter out introduction, people, services, confirmation, and suggest and move to formObject
				formObject: {
					introduction: data.introduction,
					people: data.people,
					services: data.services,
					confirmation: data.confirmation,
					suggest: data.suggest,
				},
			})}
		>
			<SimpleForm>
				<h1>Form Settings</h1>
				<Paper sx={{ width: "100%" }}>
					<Tabs
						value={activeTab}
						onChange={handleChange}
						variant="scrollable"
						scrollButtons="auto"
						aria-label="form editor"
					>
						<Tab value="introduction" label="Introduction" />
						<Tab value="people" label="People" />
						<Tab value="peopleDetails" label="People - Details" />
						<Tab
							value="peopleInformation"
							label="People - Contact Information"
						/>
						<Tab value="peopleStatus" label="People - Status" />
						<Tab value="peopleDemographics" label="People - Demographics" />
						<Tab value="peopleSafety" label="People - Safety" />
						<Tab value="services" label="Services" />
						<Tab value="confirmation" label="Confirmation" />
						<Tab value="suggest" label="Suggest a Service" />
					</Tabs>
					<Box sx={{ p: 3 }}>
						{activeTab === "introduction" && <FormIntroduction />}
						{activeTab === "people" && <FormPeople />}
						{activeTab === "peopleDetails" && <FormPeopleDetails />}
						{activeTab === "peopleInformation" && <FormPeopleInformation />}
						{activeTab === "peopleStatus" && <FormPeopleStatus />}
						{activeTab === "peopleDemographics" && <FormPeopleDemographics />}
						{activeTab === "peopleSafety" && <FormPeopleSafety />}
						{activeTab === "services" && <FormServices />}
						{activeTab === "suggest" && <FormSuggest />}
						{activeTab === "confirmation" && <FormConfirmation />}
					</Box>
				</Paper>
			</SimpleForm>
		</Edit>
	);
}
