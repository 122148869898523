import {
  useRecordContext,
	useGetIdentity,
} from "react-admin";
import HowToRegIcon from '@mui/icons-material/HowToReg';
import Tooltip from '@mui/material/Tooltip';

const OwnerIndicator = () => {
  const record = useRecordContext();
  const { identity } = useGetIdentity();
  return record.requested_user === identity.username ? <Tooltip title="Vous avez lancé cette demande"><HowToRegIcon /></Tooltip> : null;
};

export default OwnerIndicator;