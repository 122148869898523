import Box from "@mui/material/Box";
import { useEditContext, Loading } from "react-admin";
import ClientFamily from "./ClientFamily";
import ClientRevisions from "./ClientRevisions";

const ClientEditAside = () => {
	const { record, isLoading } = useEditContext();

	if (isLoading || !record)
		return (
			<Box>
				<Loading />
			</Box>
		);

	return (
		<Box>
			<ClientFamily />
			<ClientRevisions />
		</Box>
	);
};

export default ClientEditAside;
