import {
	useRecordContext,
	useGetList,
	Button,
	useUpdate,
	useNotify,
} from "react-admin";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";

const RevisionView = ({ isOpen, onClose, revision }) => {
	const record = useRecordContext();
	const [update, { updateLoading }] = useUpdate();
	const notify = useNotify();
	//Get all label definitions from the APO
	const { data: labels, isLoading: labelsLoading } = useGetList("labels", {
		pagination: { page: 1, perPage: 1000 },
	});

	if (labelsLoading || !labels || updateLoading) {
		return <CircularProgress />;
	}

	const restoreRevision = () => {
		//Restore the revision by merging in values from the revision to record and saving
		const changes = Object.keys(revision.changes).reduce((acc, key) => {
			acc[key] = revision.changes[key].from;
			return acc;
		}, {});

		const updatedRecord = {
			...record,
			...changes,
		};

		update(
			"clients",
			{
				id: record.id,
				data: updatedRecord,
			},
			{
				mutationMode: "pessimistic",
				onSuccess: () => {
					notify("Modification annulée");
					onClose();
				},
				onError: (error) =>
					notify(`Error: ${error.message}`, { type: "warning" }),
			}
		);
	};

	//Show true/false when the value is a boolean
	const formatValue = (value) => {
		if (typeof value === "boolean") {
			return value ? "Oui" : "Non";
		}
		return value;
	};

	return (
		<Dialog open={isOpen} onClose={onClose}>
			<DialogTitle>Afficher la révision</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<strong>
						{revision?.user} - {new Date(revision?.timestamp).toLocaleString()}
					</strong>
				</DialogContentText>
				<Divider sx={{ margin: "5px 0 25px 0", minWidth: "400px" }} />
				<table>
					<tbody>
						{revision
							? Object.keys(revision.changes).map((change, index) => {
									const label =
										labels?.find((label) => label.name === change)?.label ??
										change;

									//If value is array change to string
									if (Array.isArray(revision.changes[change].from)) {
										revision.changes[change].from =
											revision.changes[change].from.join(", ");
									}
									if (Array.isArray(revision.changes[change].to)) {
										revision.changes[change].to =
											revision.changes[change].to.join(", ");
									}

									return revision.changes[change].from !==
										revision.changes[change].to ? (
										<tr key={index}>
											<td>
												<strong>{label}</strong>
											</td>
											<td>
												{revision.changes[change].from !== null ? (
													<strike
														style={{
															background: "#fcf0f1",
															padding: "2px",
															margin: "2px",
														}}
													>
														{formatValue(revision.changes[change].from)}
													</strike>
												) : null}
												<span
													style={{
														background: "#edfaef",
														padding: "2px",
														margin: "2px",
													}}
												>
													{formatValue(revision.changes[change].to)}
												</span>
											</td>
										</tr>
									) : null;
							  })
							: null}
					</tbody>
				</table>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} label="Annuler" />
				<Button onClick={restoreRevision} label="Annuler cette modification" />
			</DialogActions>
		</Dialog>
	);
};

export default RevisionView;
