/*!SECTION
 * @file SmartInput.js
 * @version 1.0.0
 * @name SmartInput
 * @namespace Fields
 * @requires react-admin
 * @requires react
 * @requires @mui/material
 * @desc A smart input field that can be used to render any field from the API
 * @see https://marmelab.com/react-admin/Inputs.html
 *
 * @param {string} name - The name of the field
 */

import {
	useGetList,
	TextInput,
	DateInput,
	SelectInput,
	SelectArrayInput,
	BooleanInput,
	ReferenceInput,
	AutocompleteInput,
	ReferenceArrayInput,
	AutocompleteArrayInput,
	required,
	email,
} from "react-admin";
import ErrorIcon from '@mui/icons-material/Error';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { countryCodes } from "../Helpers/countries";


const postalValidation = (value, allValues) => {
    //Validate that value is in the format of A1A 1A1
		//Allow for blank
		if( value && !value.match(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/) ) {
			return "Code postal invalide";
		}
    
		return undefined;
};

const validatePostal = [postalValidation];


const SmartInput = ({ name, source = null, handleChange = (e) => void(null) }) => {
	//Get all field definitions from the API
	const { data: fields, isLoading: fieldsLoading } = useGetList("client/fields", {
		pagination: { page: 1, perPage: 1000 },
	});

	//Get all label definitions from the APO
	const { data: labels, isLoading: labelsLoading } = useGetList("labels", {
		pagination: { page: 1, perPage: 1000 },
	});

	if (fieldsLoading || labelsLoading || !fields || !labels) {
		return <CircularProgress />;
	}

	const field = fields?.find((fieldDef) => fieldDef.name === name);
	const label = labels?.find((label) => label.name === name)?.label ?? name;

	if( !field || !label ) { return <Typography><ErrorIcon />{`Unable to find field or labels for ${name}`}</Typography>; }

	let Input = null;

	switch (field.type) {
		case "date":
			Input = <DateInput
					source={ source || name }
					label={label}
					sx={{ width: "100%" }}
					validate={field.required && required()}
					onChange={(e) => handleChange(e) }
				/>
			break;
		case "email":
			Input = <TextInput
					source={ source || name }
					type="email"
					label={label}
					sx={{ width: "100%" }}
					validate={[email(), field.required && required()]}
					onChange={(e) => handleChange(e) }
				/>
			break;
		case "enumeration":
			Input = <SelectInput
					source={ source || name }
					label={label}
					sx={{ width: "100%" }}
					choices={field.enum.map((value) => ({
						id: value,
						name: labels.find((label) => label.name === `${name}_${value}`)?.label, //choices are stored as fieldname_choicename in the labels table
					}))}
					validate={field.required && required()}
					onChange={(e) => handleChange(e) }
				/>
			break;
		case "relation":
			let target = field.target.split(".")[1];
			//If last letter is a y, add "ies" to the end of the word, otherwise add "s"
			if( target.charAt(target.length - 1) === "y" ) {
				target = target.slice(0, -1) + "ies";
			}else{
				target += "s";
			}

			if( field.relation === "oneToMany" ) {
				Input = <ReferenceArrayInput
						source={ source || name }
						sx={{ width: "100%" }}
						reference={target}
						validate={field.required && required()}
					>
						<AutocompleteArrayInput label={label} optionText="name" />
					</ReferenceArrayInput>
			}else if( field.relation === "oneToOne" ) {
				Input = <ReferenceInput
						source={ source || name }
						sx={{ width: "100%" }}
						reference={target}
						validate={field.required && required()}
						perPage={1000}
					>
						<AutocompleteInput label={label} optionText="name" />
					</ReferenceInput>
			}
			break;
		case "boolean":
			Input = <BooleanInput
					source={ source || name }
					label={label}
					sx={{ width: "100%" }}
					validate={field.required && required()}
					onChange={(e) => handleChange(e) }
				/>
			break;
		case "json":
			if( field.customField && field.customField.includes("multi-select") ) {
				Input = <SelectArrayInput
						source={source || name}
						sx={{ width: "100%" }}
						choices={field.options.map((value) => ({
							id: value.split(":")[0],
							name: value.split(":")[1],
						}))}
						onChange={(e) => handleChange(e) }
					/>
			}
			break;
		case "string":
			if( field.customField && field.customField.includes("country-select") ) {
				Input = <SelectInput
						source={ source || name }
						sx={{ width: "100%" }}
						choices={ countryCodes }
						validate={field.required && required()}
						onChange={(e) => handleChange(e) }
					/>
			}else{
				Input = <TextInput
						source={ source || name }
						label={label}
						sx={{ width: "100%" }}
						validate={ name === 'postal_code' ? validatePostal : field.required && required()}
						onChange={(e) => handleChange(e) }
					/>
			}
			break;
		default:
			Input = <TextInput
					source={ source || name }
					label={label}
					sx={{ width: "100%" }}
					validate={ name === 'postal_code' ? validatePostal : field.required && required()}
					onChange={(e) => handleChange(e) }
				/>
	}

	return Input;
};

export default SmartInput;
