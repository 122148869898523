import { useState, useEffect } from "react";
import { useGetIdentity } from "react-admin";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PendingIcon from "@mui/icons-material/Pending";
import PhoneDisabledIcon from "@mui/icons-material/PhoneDisabled";
import EmailIcon from "@mui/icons-material/Email";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

export default function ReceivedStatus({ isOpen, closeDialog, updateStatus }) {
	const { data: identity, isLoading: identityLoading } = useGetIdentity();
	const [open, setOpen] = useState(isOpen);
	const [status, setStatus] = useState("pending");
  const [otherStatus, setOtherStatus] = useState("");

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

	const handleClose = () => {
    closeDialog();
	};

  const handleSelect = (status) => {
    setStatus(status);
    updateStatus({
      status: status,
      user: identity.username,
      date: new Date().toISOString(),
      activity: 'dashboard_status_change'
    },'received');
    closeDialog();
  }

	if( identityLoading ) return null;

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Set Status</DialogTitle>
			<DialogContent>
				<Button size="large" fullWidth={true} startIcon={<PendingIcon />} onClick={() => handleSelect("pending")}>Pending</Button>
				<Button size="large" fullWidth={true} startIcon={<PhoneDisabledIcon />} onClick={() => handleSelect("not_reachable")}>Not Reachable</Button>
				<Button size="large" fullWidth={true} startIcon={<EmailIcon />} onClick={() => handleSelect("message_left")}>Message Left</Button>
				<Button size="large" fullWidth={true} startIcon={<NotInterestedIcon />} onClick={() => handleSelect("not_interested")}>No longer interested</Button>
				<Button size="large" fullWidth={true} startIcon={<CheckCircleIcon />} onClick={() => handleSelect("complete")}>Received</Button>
				<Button size="large" fullWidth={true} startIcon={<ControlPointIcon />} onClick={() => setStatus("other")} variant={ status === 'other' ? 'contained' : 'text' }>Other</Button>
				{status === "other" && (
					<TextField
						autoFocus
						margin="dense"
						id="other_status"
						label="Other Status"
						type="text"
						fullWidth
						variant="standard"
            value={otherStatus}
            onChange={(e) => setOtherStatus(e.target.value)}
					/>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={() => handleSelect(null)}>Reset</Button>
				<Button onClick={handleClose}>Cancel</Button>
				{status === "other" && ( <Button onClick={() => handleSelect(otherStatus)}>Update</Button> )}
			</DialogActions>
		</Dialog>
	);
}