import { Edit } from "react-admin";
import { SuggestionList as SuggestionListComponent } from "./SuggestionList";
import SuggestionForm from "./SuggestionForm";

//Note: it's a bit redundant to do this instead of direct importing into App.js, but we may want to add additional logic and UI later
export const SuggestionList = SuggestionListComponent;
export const SuggestionEdit = (props) => (
	<Edit {...props}>
		<SuggestionForm {...props} />
	</Edit>
);
