import {Button } from "react-admin";
import { Box } from "@mui/material";
import SegmentFilter from "./SegmentFilter";
import AddIcon from "@mui/icons-material/AddCircle";

const SegmentFilterGroup = ({ filterGroup, filterGroupIndex, updateFilters }) => {
	console.log("SegmentFilterGroup", filterGroup);
	return (
		<Box flex="11">
			{filterGroup.map((filter, index) => {
				return (
					<SegmentFilter key={index} filter={filter} filterIndex={index} filterGroupIndex={filterGroupIndex} updateFilters={updateFilters} />
				);
			})}
			{filterGroup.length === 0 ? (
				<Button
					label="Add filter"
					type="button"
					onClick={() => updateFilters({ type: "addFilter", index: filterGroupIndex })}
				>
					<AddIcon />
				</Button>
			) : (
				""
			)}
		</Box>
	);
};

export default SegmentFilterGroup;
