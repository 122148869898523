import FamilyManage from "./FamilyManage";

const FamilyCreate = ({ isOpen, onClose }) => {
	return (
		isOpen ? <FamilyManage
			isOpen={isOpen}
			onClose={onClose}
		/> : null
	);
};

export default FamilyCreate;
