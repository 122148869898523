import { SelectInput } from "react-admin";
export function FieldTypes() {
	return (
		<SelectInput
			source="type"
			label="Type"
			choices={[
				{ id: "text", name: "Text" },
				{ id: "email", name: "Email" },
				{ id: "number", name: "Number" },
				{ id: "tel", name: "Telephone" },
				{ id: "url", name: "URL" },
			]}
		/>
	);
}
