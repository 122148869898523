/*!SECTION
 * @file SmartInput.js
 * @version 1.0.0
 * @name SmartInput
 * @namespace Fields
 * @requires react-admin
 * @requires react
 * @requires @mui/material
 * @desc A smart input field that can be used to render any field from the API
 * @see https://marmelab.com/react-admin/Inputs.html
 *
 * @param {string} name - The name of the field
 */

import {
	useGetList,
	TextField,
	DateField,
	SelectField,
	ReferenceField,
	ReferenceArrayField,
	SingleFieldList,
	ChipField,
} from "react-admin";
import ErrorIcon from "@mui/icons-material/Error";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import { countryCodes } from "../Helpers/countries";

const SmartField = ({ name, source = null }) => {
	//Get all field definitions from the API
	const { data: fields, isLoading: fieldsLoading } = useGetList(
		"client/fields",
		{
			pagination: { page: 1, perPage: 1000 },
		}
	);

	//Get all label definitions from the APO
	const { data: labels, isLoading: labelsLoading } = useGetList("labels", {
		pagination: { page: 1, perPage: 1000 },
	});

	if (fieldsLoading || labelsLoading || !fields || !labels) {
		return <CircularProgress />;
	}

	const field = fields?.find((fieldDef) => fieldDef.name === name);
	const label = labels?.find((label) => label.name === name)?.label ?? name;

	if (!field || !label) {
		return (
			<Typography>
				<ErrorIcon />
				{`Unable to find field or labels for ${name}`}
			</Typography>
		);
	}

	var fieldComponent = null;

	switch (field.type) {
		case "date":
			fieldComponent = (
				<DateField
					source={source || name}
					sx={{ width: "100%", minHeight: "20px", display: "block" }}
				/>
			);
			break;
		case "email":
			fieldComponent = (
				<TextField
					source={source || name}
					type="email"
					sx={{ width: "100%", minHeight: "20px", display: "block" }}
				/>
			);
			break;
		case "enumeration":
			fieldComponent = (
				<SelectField
					source={source || name}
					sx={{ width: "100%", minHeight: "20px", display: "block" }}
					choices={field.enum.map((value) => ({
						id: value,
						name: labels.find((label) => label.name === `${name}_${value}`)
							?.label, //choices are stored as fieldname_choicename in the labels table
					}))}
				/>
			);
			break;
		case "relation":
			let target = field.target.split(".")[1];
			//If last letter is a y, add "ies" to the end of the word, otherwise add "s"
			if (target.charAt(target.length - 1) === "y") {
				target = target.slice(0, -1) + "ies";
			} else {
				target += "s";
			}

			if (field.relation === "oneToMany") {
				fieldComponent = (
					<ReferenceArrayField
						source={source || name}
						sx={{ width: "100%", minHeight: "20px", display: "block" }}
						reference={target}
					>
						<SingleFieldList>
							<ChipField source="name" />
						</SingleFieldList>
					</ReferenceArrayField>
				);
				break;
			} else if (field.relation === "oneToOne") {
				fieldComponent = (
					<ReferenceField
						source={source || name}
						sx={{ width: "100%", minHeight: "20px", display: "block" }}
						reference={target}
					>
						<SingleFieldList>
							<ChipField source="name" />
						</SingleFieldList>
					</ReferenceField>
				);
				break;
			}
			break;
		case "boolean":
			fieldComponent = (
				<TextField //Returning a textfield because boolean SVGs don't properly render in printed PDF
					source={source || name}
					sx={{ width: "100%", minHeight: "20px", display: "block" }}
				/>
			);
			break;
		case "json":
			if (field.customField && field.customField.includes("multi-select")) {
				fieldComponent = (
					<SelectField
						source={source || name}
						sx={{ width: "100%", minHeight: "20px", display: "block" }}
						choices={field.options.map((value) => ({
							id: value.split(":")[0],
							name: value.split(":")[1],
						}))}
					/>
				);
			};
			break;
		case "string":
			if (field.customField && field.customField.includes("country-select")) {
				fieldComponent = (
					<SelectField
						source={source || name}
						sx={{ width: "100%", minHeight: "20px", display: "block" }}
						choices={countryCodes}
					/>
				);
			} else {
				fieldComponent = (
					<TextField
						source={source || name}
						sx={{ width: "100%", minHeight: "20px", display: "block" }}
					/>
				);
			}
			break;
		default:
			fieldComponent = (
				<TextField
					source={source || name}
					sx={{ width: "100%", minHeight: "20px", display: "block" }}
				/>
			);
			break;
	}

	return (
		<Card raised={false}>
			<CardContent>
				<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
					{label}
				</Typography>
				<Typography variant="body2">{fieldComponent}</Typography>
			</CardContent>
		</Card>
	);
};

export default SmartField;
