import { useState } from "react";
import { Card, CardContent, CardHeader, Box, Typography, Button } from '@mui/material';
import CityTable from './CityTable.js'
import DemographicsTable from "./DemographicsTable.js";
import ClientTable from './ClientTable.js'
import RequestTable from './RequestTable.js'
import VulnerabilityTable from './VulnerabilityTable.js'
import NewTable from './NewTable.js'
import SourceTable from './SourceTable.js'
import AgentTable from './AgentTable.js'
import { DateInput, SimpleForm } from "react-admin";


const Reports = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    //TODO: ensure styling of dateOutputs corresponds to error state
    // const hasStartDateError = ( (endDate && !startDate) || (endDate < startDate ) );
    // const hasEndDateError =  ( (startDate && !endDate) || (startDate > endDate ) );

    return( 
        <>
            <SimpleForm toolbar={null} sx={{ maxWidth: 600 }} redirect={false}>
                <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                    <Box flex={1} alignItems="center" display="flex">
                        <DateInput
                            id="startDate"
                            label="Start Date"
                            source="startDate"
                            // onChange={(e) => setStartDate(e.target.value)}
                            // validate={() => hasStartDateError ? 'required' : undefined} //This isn't working
                        />  
                    </Box>
                    <Box flex={1} alignItems="center" display="flex">
                        <DateInput
                            id="endDate"
                            label="End Date"
                            source="endDate"
                            // onChange={(e) => setEndDate(e.target.value)}
                            // validate={() => hasEndDateError ? 'required' : undefined} //This isn't working
                        />   
                    </Box>
                    <Box flex={1} pt="15px">
                        <Button 
                            // variant="contained" 
                            // color="primary"
                            onClick={(e) => {
                                e.preventDefault();
                                setStartDate(e.target.closest('form').elements.startDate.value);
                                setEndDate(e.target.closest('form').elements.endDate.value);
                            }
                        }>Filter</Button>
                    </Box>
                </Box>
            </SimpleForm>
            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                <Box sx={{ flex: "100%" }} ><Typography variant="h5">Reports</Typography></Box>
                <Box sx={{ flex: 1 }}>
                  <Box sx={{ marginBottom: 2 }}>
                    <Card>
                        <CardHeader title="Cities" />
                        <CardContent>
                            <CityTable startDate={startDate} endDate={endDate} />
                        </CardContent>
                    </Card>
                  </Box>
                  <Box sx={{ marginBottom: 2 }}>
                    <Card>
                        <CardHeader title="Demographics" />
                        <CardContent>
                            <DemographicsTable startDate={startDate} endDate={endDate} />
                        </CardContent>
                    </Card>
                  </Box>
                </Box>
                <Box sx={{ flex: 3 }}>
                    <Box sx={{ marginBottom: 2 }}>
                        <Card>
                            <CardHeader title="Requests" />
                            <CardContent>
                                <RequestTable startDate={startDate} endDate={endDate} />
                            </CardContent>
                        </Card>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2 }} >
                        <Box sx={{ flex: 1 }}>
                          <Box sx={{ marginBottom: 2 }}>
                            <Card>
                                <CardHeader title="Client Base" />
                                <CardContent>
                                    <ClientTable startDate={startDate} endDate={endDate} />
                                </CardContent>
                            </Card>
                          </Box>
                          <Box sx={{ marginBottom: 2 }}>
                            <Card>
                                <CardHeader title="Client Source" />
                                <CardContent>
                                    <SourceTable startDate={startDate} endDate={endDate} />
                                </CardContent>
                            </Card>
                          </Box>
                        </Box>
                        <Box sx={{ flex: 1 }}>
                            <Box sx={{ marginBottom: 2 }}>
                                <Card>
                                    <CardHeader title="HAT Referral Reasons" />
                                    <CardContent>
                                        <VulnerabilityTable startDate={startDate} endDate={endDate} />
                                    </CardContent>
                                </Card>
                            </Box>
                            <Box sx={{ marginBottom: 2 }}>
                                <Card>
                                    <CardHeader title="New vs Returning" />
                                    <CardContent>
                                        <NewTable startDate={startDate} endDate={endDate} />
                                    </CardContent>
                                </Card>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ marginBottom: 2 }}>
                        <Card>
                            <CardHeader title="Agents" />
                            <CardContent>
                                <AgentTable startDate={startDate} endDate={endDate} />
                            </CardContent>
                        </Card>
                    </Box>
                </Box>
            </Box>
        </>
    )
};

export default Reports;