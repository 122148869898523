import {
	useEditContext,
	Loading,
	SimpleForm,
	useGetList,
	RadioButtonGroupInput,
	TextInput,
  useRedirect,
  useNotify,
  Toolbar,
  SaveButton
} from "react-admin";
import Grid from "@mui/material/Grid";

const MergeToolbar = () => {
  const redirect = useRedirect();
  const notify = useNotify();

  return (
    <Toolbar>
      <SaveButton
        label="Fusionner les doublons"
        mutationOptions={{
          onSuccess: (data) => {
            notify("Les doublons ont été fusionnés", {
              type: "info",
              messageArgs: { smart_count: 1 },
            });
            redirect(`/duplicates/`);
          },
        }}
        type="button"
        variant="text"
      />
    </Toolbar>
  );
};

const DuplicateClients = () => {
	const { record, isLoading } = useEditContext();
	const { data: labels, labelsLoading } = useGetList("labels", {
		pagination: { page: 1, perPage: 1000 },
	});
	if ((isLoading, labelsLoading)) return <Loading />;

	const skipValues = [
		"id",
		"createdAt",
		"updatedAt",
		"publishedAt",
		"vuid",
		"versionNumber",
		"isVisibleInListView",
		"uid",
		"is_minor",
		"partial_record",
		"post_id",
	];

	return (
		<SimpleForm toolbar={<MergeToolbar/>}>
			<Grid container spacing={2} sx={{ padding: 3 }}>
        <Grid item xs={8} key="introduction">
          <h1>Fusionner les doublons</h1>
          <p>Sélectionnez les données que vous souhaitez conserver pour chaque champ. Lorsque vous cliquez sur "Fusionner les doublons", cela créera un nouveau dossier client unique avec les données sélectionnées et fusionnera toutes les notes, demandes et envois d'e-mails de tous les doublons.</p>
        </Grid>
				<Grid item xs={12} key="duplicates" sx={{ display: "none" }}>
					<TextInput source="primary_id" defaultValue={record.primary.id} />
					<TextInput
						source="duplicate_ids"
						defaultValue={`[${record.clients.data
							.map((client) => client.id)
							.join(",")}]`}
					/>
				</Grid>
				{Object.entries(record.primary.attributes)
					.filter(([key, value]) => !skipValues.includes(key))
					.map(([key, value, index]) => {
						return (
							<Grid item xs={12} key={index}>
								<RadioButtonGroupInput
									sx={{
										"& .RaRadioButtonGroupInput-label": {
											width: "100%",
										},
									}}
									key={index}
									fullWidth={true}
									source={key}
									defaultValue={record.primary.id}
									label={
										labels?.find((label) => label.name === key)?.label ?? key
									}
									choices={[
										{ id: record.primary.id, name: value },
										...record.clients.data.map((client) => ({
											id: client.id,
											name: client.attributes[key],
										})),
									]}
								/>
							</Grid>
						);
					})}
			</Grid>
		</SimpleForm>
	);
};

export default DuplicateClients;
