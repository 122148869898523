import { useState, useEffect } from 'react';
import { useDataProvider, Loading } from 'react-admin';
import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';

const ClientTable = ({ startDate, endDate }) => {
    const dataProvider = useDataProvider();
    const [clients, setClients] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    useEffect(() => {
        setLoading(true);
        const query = { id: 'clients', meta: { startDate: startDate, endDate: endDate} };
        dataProvider.getOne('report', query )
            .then(({ data }) => {
                setClients(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
        }, [dataProvider, startDate, endDate]);

    if (loading) return <Loading />;
    if (error) return null;
    if (!clients) return null;

    return(
        <TableContainer>
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row">Number of primary profiles</TableCell>
                        <TableCell align="right">{clients.primaries}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">Number of primary profiles without secondary profiles</TableCell>
                        <TableCell align="right">{clients.individuals}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">Number of primary profiles with secondary profiles</TableCell>
                        <TableCell align="right">{clients.families}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">Number of secondary profiles</TableCell>
                        <TableCell align="right">{clients.secondaries}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">Number of Minors</TableCell>
                        <TableCell align="right">{clients.minors}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">Number of partial profiles (not completed)</TableCell>
                        <TableCell align="right">{clients.partials}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row"><Typography variant="h6">Total Clients:</Typography></TableCell>
                        <TableCell align="right"><Typography variant="h6">{clients.total}</Typography></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}


export default ClientTable;