import * as React from "react";
import { Card, CardContent, CardHeader } from "@mui/material";

const Panel = ({ title, children }) => {
	return (
		<Card sx={{ marginBottom: 4, marginLeft: 2, width: "15em" }}>
			<CardHeader
				title={title}
				sx={{
					padding: "0px 12px",
					minHeight: "48px",
					textAlign: "center",
					borderBottom: "solid 1px rgba(0, 0, 0, 0.12)",
				}}
				titleTypographyProps={{
					variant: "overline",
					sx: { fontSize: "0.875rem", lineHeight: "1.75" },
				}}
			/>
			<CardContent>{children}</CardContent>
		</Card>
	);
};

export default Panel;
