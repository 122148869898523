import { useState, useEffect } from "react";
import SmartInput from "../../Fields/SmartInput";
import ServicesInput from "../../Fields/ServicesInput";
import Box from "@mui/material/Box";
import { SelectInput, useGetList } from "react-admin";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/AddCircle";
import RemoveIcon from "@mui/icons-material/RemoveCircle";

const SegmentFilter = ({
	filter,
	filterIndex,
	filterGroupIndex,
	updateFilters,
}) => {
	const [type, setType] = useState(filter.type || "fields");
	const [key, setKey] = useState(filter.key || "first_name");
	const [compare, setCompare] = useState(filter.compare || "LIKE");
	const [value, setFieldValue] = useState(filter.value || "");
	const [serviceState, setServiceState] = useState(filter.state || "requested");
	const [services, setServices] = useState(filter.services || []);

	//Get all field definitions from the API
	const { data: fields, fieldsLoading } = useGetList("client/fields", {
		pagination: { page: 1, perPage: 1000 },
	});

	useEffect(() => {
		updateFilters({
			type: "updateFilter",
			index: filterGroupIndex,
			filterIndex: filterIndex,
			filter: {
				type,
				key,
				compare,
				value,
				state: serviceState,
				services,
			},
		});
	}, [
		type,
		key,
		compare,
		value,
		serviceState,
		services,
		filterGroupIndex,
		filterIndex,
		updateFilters,
	]);

	if (fieldsLoading || !fields) {
		return null;
	}

	const typeChoices = [
		{ id: "fields", name: "Form Fields" },
		{ id: "services", name: "Services" },
	];

	const fieldChoices = Object.entries(fields).map(([id, field]) => ({
		id: field.name,
		name: field.name,
	}));

	const compareChoices = [
		{ id: "LIKE", name: "equals" },
		{ id: "NOT LIKE", name: "does not equal" },
		{ id: "IN", name: "contains" },
		{ id: "NOT IN", name: "does not contain" },
		{ id: ">", name: "Greater than" },
		{ id: ">=", name: "Greater than or equal to" },
		{ id: "<", name: "Less than" },
		{ id: "<=", name: "Less than or equal to" },
		{ id: "EXISTS", name: "exists" },
		{ id: "NOT EXISTS", name: "does not exist" },
	];

	const stateChoices = [
		{ id: "requested", name: "Requested" },
		{ id: "referred", name: "Referred" },
		{ id: "received", name: "Received" },
	];

	return (
		<Box display="flex" bgcolor="rgba(0,0,0,0.0.5)" w="100%">
			<Box p="0 0.5em" key="type">
				<SelectInput
					source={`filters[${filterGroupIndex}][${filterIndex}].type`}
					label="Type"
					choices={typeChoices}
					defaultValue={type}
					onChange={(e) => setType(e.target.value)}
				/>
			</Box>

			{/*FROM FIELD FILTERS */}
			{type === "fields" ? (
				<Box p="0 0.5em" key="fields">
					<SelectInput
						source={`filters[${filterGroupIndex}][${filterIndex}].key`}
						label="Field"
						choices={fieldChoices}
						defaultValue={key}
						onChange={(e) => setKey(e.target.value)}
					/>
				</Box>
			) : (
				""
			)}
			{type === "fields" ? (
				<Box p="0 0.5em" key="compare">
					<SelectInput
						source={`filters[${filterGroupIndex}][${filterIndex}].compare`}
						label="Compare"
						choices={compareChoices}
						defaultValue={compare}
						onChange={(e) => setCompare(e.target.value)}
					/>
				</Box>
			) : (
				""
			)}
			{type === "fields" ? (
				<Box p="0 0.5em" key="field_value">
					<SmartInput
						name={key}
						source={`filters[${filterGroupIndex}][${filterIndex}].value`}
						defaultValue={value}
						handleChange={(e) =>
							setFieldValue(
								e.target.type === "checkbox" ? e.target.checked : e.target.value
							)
						}
					/>
				</Box>
			) : (
				""
			)}

			{/*SERVICE GROUP FILTERS*/}
			{type === "services" ? (
				<Box p="0 0.5em" key="services">
					<ServicesInput
						source={`filters[${filterGroupIndex}][${filterIndex}].services`}
						defaultValue={services}
						onChange={(e) => setServices(e.target.value)}
					/>
				</Box>
			) : (
				""
			)}
			{type === "services" ? (
				<Box p="0 0.5em" key="state">
					<SelectInput
						source={`filters[${filterGroupIndex}][${filterIndex}].state`}
						label="State"
						choices={stateChoices}
						defaultValue={serviceState}
						onChange={(e) => setServiceState(e.target.value)}
					/>
				</Box>
			) : (
				""
			)}

			{/*ADD MORE FILTERS*/}
			<Box p="1em 1em" key="buttons">
				<Button
					label="AND"
					type="button"
					onClick={() =>
						updateFilters({ type: "addFilter", index: filterGroupIndex })
					}
				>
					<AddIcon />
				</Button>
				{filterIndex > 0 ? (
					<Button
						type="button"
						onClick={() =>
							updateFilters({
								type: "removeFilter",
								index: filterGroupIndex,
								filterIndex: filterIndex,
							})
						}
					>
						<RemoveIcon />
					</Button>
				) : (
					""
				)}
			</Box>
		</Box>
	);
};

export default SegmentFilter;
