import DuplicateClients from './DuplicateClients';
import { Edit } from 'react-admin';

const DuplicateMerge = () => {
  return (
    <Edit queryOptions={{meta: { returnRecords: true }}} mutationMode="pessimistic">
      <DuplicateClients />
    </Edit>
  );
}

export default DuplicateMerge;