import * as React from 'react';
import { Logout, UserMenu, useUserMenu } from 'react-admin';
import { MenuList, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

const ConfigurationMenu = React.forwardRef((props, ref) => {
    const { onClose } = useUserMenu();
    return (
        <MenuItem
            ref={ref}
            {...props}
            to="/profile"
            onClick={() => { window.location = "/#/profile"; onClose(); } }//We're not suppoed to have to do this, but our custom route isn't working
            sx={{ color: 'text.secondary' }}
        >
            <ListItemIcon>
                <SettingsIcon />
            </ListItemIcon>
            <ListItemText>Mon profil</ListItemText>
        </MenuItem>
    );
});

export const CustomUserMenu = () => (
    <UserMenu>
        <MenuList>
            <ConfigurationMenu />
            <Logout />
        </MenuList>
    </UserMenu>
);