import {
	TextInput,
	Form,
	SaveButton,
	DeleteButton,
} from "react-admin";
import { Typography, Box, Toolbar } from "@mui/material";
import SegmentFilters from "./SegmentFilters";

const SegmentForm = () => {
	return (
		<Form>
			<Box p="1em">
				<TextInput source="name" label="Name" fullWidth />
				<TextInput
					source="description"
					label="Description"
					fullWidth
					multiline
				/>
			</Box>
			<Box p="1em">
				<Typography variant="h5">Add Filters</Typography>
			</Box>
			<Box p="1em">
				<SegmentFilters />
			</Box>
			<Toolbar>
				<Box display="flex" justifyContent="space-between" width="100%">
					<SaveButton alwaysEnable />
					<DeleteButton />
				</Box>
			</Toolbar>
		</Form>
	);
};

export default SegmentForm;
