import Grid from "@mui/material/Grid";
import { TextInput } from "react-admin";
import { RichTextInput } from "ra-input-rich-text";

export default function FormConfirmation() {
	const section = "confirmation";

	return (
		<Grid container spacing={2} sx={{ width: "100%" }}>
			<Grid item xs={12}>
				<h3>Header</h3>
			</Grid>
			<Grid item xs={4}>
				<TextInput
					source={`${section}.heading.fr`}
					label="Heading (FR)"
					sx={{ width: "100%" }}
				/>
			</Grid>
			<Grid item xs={2}></Grid>
			<Grid item xs={4}>
				<TextInput
					source={`${section}.heading.en`}
					label="Heading (EN)"
					sx={{ width: "100%" }}
				/>
			</Grid>
			<Grid item xs={6}>
				<RichTextInput
					source={`${section}.message.fr`}
					label="Message (FR)"
					sx={{ width: "100%" }}
					multiline
				/>
			</Grid>
			<Grid item xs={6}>
				<RichTextInput
					source={`${section}.message.en`}
					label="Message (EN)"
					sx={{ width: "100%" }}
					multiline
				/>
			</Grid>
		</Grid>
	);
}
