import React from 'react';
import { useGetOne, Loading } from 'react-admin';
import { Typography, Box } from '@mui/material';

// const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

const Comment = ({note}) => {
    //Get note data
    const { data, isLoading } = useGetOne(
        "notes",
        { id: note },
    );
    
    if (isLoading || !data) {
        return <Loading />
    }

    const { text, user, createdAt } = data;

    const date = new Date(createdAt);
    return (
    <Box sx={{marginBottom: 6}}>
        <Box sx={{borderRadius: 5, padding: "10px 30px", marginBottom: 2, backgroundColor: '#F0F0F0', maxWidth: 660 }}>
            <div dangerouslySetInnerHTML={{__html: text}} />
            <div style={{width: 0, height: 0, borderLeft: "20px solid transparent", borderRight: "20px solid transparent", borderTop: "20px solid #F0F0F0", margin: "0 0 -20px"}}></div>
        </Box>
        <Box sx={{marginLeft: 4}}><Typography variant="body2">{user}</Typography><Typography variant="caption">{date.toLocaleString()}</Typography></Box>
    </Box>
    )
};

export default Comment;