import { TextInput, SelectInput, Edit, SimpleForm } from "react-admin";

export const SuggestionForm = () => {
	return (
		<Edit>
			<SimpleForm>
				<TextInput
					source="organization"
					label="Organization"
					fullWidth={true}
				/>
				<TextInput
					source="contact_name"
					label="Contact Name"
					fullWidth={true}
				/>
				<TextInput
					source="contact_email"
					label="Contact Email"
					fullWidth={true}
				/>
				<TextInput source="address_1" label="Address Line 1" fullWidth={true} />
				<TextInput source="address_2" label="Address Line 2" fullWidth={true} />
				<TextInput source="city" label="City" fullWidth={true} />
				<TextInput source="postal" label="Postal" fullWidth={true} />
				<TextInput source="admin_email" label="Admin Email" fullWidth={true} />
				<TextInput
					source="referrals_email"
					label="Referrals Email"
					fullWidth={true}
				/>
				<SelectInput
					source="status"
					label="Status"
					choices={[
						{ id: "pending", name: "Pending" },
						{ id: "hold", name: "Hold" },
						{ id: "approved", name: "Approved" },
						{ id: "rejected", name: "Rejected" },
					]}
					fullWidth={true}
				/>
			</SimpleForm>
		</Edit>
	);
};

export default SuggestionForm;
