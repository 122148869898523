import { useRecordContext, useGetList, Loading } from "react-admin";
import {
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
} from "@mui/material";

const RequestsSummary = () => {
	const record = useRecordContext();

	const { data, isLoading, error } = useGetList("requests", {
		pagination: { page: 1, perPage: 100 },
		filter: { client: record?.id ?? 0 },
	});
	if (isLoading) {
		return <Loading />;
	}
	if (error) {
		return <p>ERROR</p>;
	}

	return (
		<TableContainer>
			<Table 
        size="small"
        sx={{
          '& .MuiTableCell-root': {
            fontSize:"10px",
            whiteSpace: "nowrap"
          }
        }}
      >
				<TableHead>
					<TableRow>
						<TableCell>Service</TableCell>
						<TableCell>Requested</TableCell>
						<TableCell>Referred</TableCell>
						<TableCell>Completed</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{data.map((request) => (
						<TableRow key={request.id}>
							<TableCell
								component="th"
								scope="row"
								style={{ fontWeight: "bold"}}
							>
								{request.service.data.attributes.name}
							</TableCell>
							<TableCell>
								{request.requested
									? new Date(request.requested).toLocaleString()
									: ""}
							</TableCell>
							<TableCell>
								{request.referred
									? new Date(request.referred).toLocaleString()
									: ""}
							</TableCell>
							<TableCell>
								{request.received
									? new Date(request.received).toLocaleString()
									: ""}{" "}
								{request.received_status ? `(${request.received_status})` : ""}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default RequestsSummary;
