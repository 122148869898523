import Grid from "@mui/material/Grid";
import { TextInput } from "react-admin";
import Fields from "./Fields";

export default function FormIntroduction() {
	const section = "introduction";

	return (
		<Grid container spacing={2} sx={{ width: "100%" }}>
			<Grid item xs={12}>
				<h3>Header</h3>
			</Grid>
			<Grid item xs={4}>
				<TextInput
					source={`${section}.title.fr`}
					label="Title (FR)"
					sx={{ width: "100%" }}
				/>
			</Grid>
			<Grid item xs={2}></Grid>
			<Grid item xs={4}>
				<TextInput
					source={`${section}.title.en`}
					label="Title (EN)"
					sx={{ width: "100%" }}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextInput
					source={`${section}.introduction.fr`}
					label="Introduction (FR)"
					sx={{ width: "100%" }}
					multiline
				/>
			</Grid>
			<Grid item xs={6}>
				<TextInput
					source={`${section}.introduction.en`}
					label="Introduction (EN)"
					sx={{ width: "100%" }}
					multiline
				/>
			</Grid>
			<Grid item xs={6}>
				<TextInput
					source={`${section}.help.fr`}
					label="Help Text (FR)"
					sx={{ width: "100%" }}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextInput
					source={`${section}.help.en`}
					label="Help Text (EN)"
					sx={{ width: "100%" }}
				/>
			</Grid>
			<Grid item xs={12}>
				<Fields
					title="UserTypes (step: 'userType')"
					source={`${section}.userTypes`}
					disableManagement={true}
				/>
			</Grid>

			<Grid item xs={12}>
				<Fields
					title="Contact (step: 'contact')"
					source={`${section}.contact`}
				/>
			</Grid>
		</Grid>
	);
}
