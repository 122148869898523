import { useRef } from "react";
import {
	Show,
	useRecordContext,
	TopToolbar,
	EditButton,
	Button,
} from "react-admin";
import SmartField from "../../Fields/SmartField";
import { Grid, Typography, Divider, Paper } from "@mui/material";
import Comment from "../../Components/Comment";
import RequestsSummary from "../../Components/RequestsSummary";
import Send from "../../Components/Send";
import jsPDF from "jspdf";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const ClientTitle = () => {
	const record = useRecordContext();
	return (
		<span>
			Client: {record ? `${record.first_name} ${record.last_name}` : ""}
		</span>
	);
};

const ClientNotes = () => {
	const record = useRecordContext();
	return record && record.notes && Array.isArray(record.notes)
		? record.notes.map((note, index) => <Comment key={index} note={note} />)
		: "";
};

const ClientEmails = () => {
	const record = useRecordContext();
	return record && record.sends && Array.isArray(record.sends) ? (
		<Grid container spacing={2} sx={{ width: "100%", padding: 4 }}>
			<Grid item xs={12}>
				<Typography variant="h5">Emails</Typography>
			</Grid>
			<Grid item xs={8}>
				{record.sends.map((send, index) => {
					return <Send key={index} send={send} />;
				})}
			</Grid>
			<Grid item xs={4}></Grid>
		</Grid>
	) : (
		""
	);
};

const PDFButton = ({ showRef }) => {
	const handleGeneratePdf = () => {
		const doc = new jsPDF({
			format: "a4",
			unit: "px",
			hotfixes: ["px_scaling"],
		});

		doc.html(showRef.current, {
			async callback(doc) {
				await doc.save("document");
			},
			autoPaging: "text",
			width: 740,
			windowWidth: 740,
		});
	};

	return (
		<Button
			children={<PictureAsPdfIcon />}
			label="Générer un PDF"
			onClick={handleGeneratePdf}
		/>
	);
};

const ClientShowActions = ({ showRef }) => (
	<TopToolbar>
		<EditButton />
		<PDFButton showRef={showRef} />
	</TopToolbar>
);

const ClientShow = () => {
	const showRef = useRef();

	return (
		<Show
			title={<ClientTitle />}
			actions={<ClientShowActions showRef={showRef} />}
		>
			{/* this div is what our PDF generation function targets */}
			<div ref={showRef}>
				<Paper
					container
					sx={{
						padding: 4,
						"& .MuiPaper-elevation1": {
							boxShadow: "none",
						},
						"& .MuiTypography-body1": {
							color: "#666",
							fontSize: "10px",
							textTransform: "uppercase",
						},
						"& .MuiCardContent-root": {
							padding: "5px 0px 5px 0px",
							marginBottom: "5px",
						},
						"& .MuiDivider-root": { display: "none" },
						"& .MuiChip-root": { color: "black", background: "transparent" },
						"& .MuiChip-labelMedium ": { paddingLeft: "0" },
						"& .MuiButtonBase-root ": { padding: "0", margin: "0" },
						"& .MuiToolbar-root": { display: "none" },
						"& .MuiTableCell-paddingCheckbox": { display: "none" },
						"& .MuiAccordionSummary-content": {
							"& .MuiTypography-body1": {
								color: "black",
								fontSize: "0.875rem",
								textTransform: "none",
							},
						},
						"& .MuiAccordionSummary-expandIconWrapper": { display: "none" },
					}}
				>
					<Grid container spacing={2} sx={{ width: "100%", padding: 4 }}>
						<Grid item xs={12}>
							<Typography variant="h4">
								<ClientTitle />
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h5">Basic Details</Typography>
						</Grid>
						<Grid item xs={4}>
							<SmartField name="first_name" />
						</Grid>
						<Grid item xs={4}>
							<SmartField name="last_name" />
						</Grid>
						<Grid item xs={4}>
							<SmartField name="birthdate" />
						</Grid>
						<Grid item xs={4}>
							<SmartField name="email" />
						</Grid>
						<Grid item xs={4}>
							<SmartField name="telephone" />
						</Grid>
						<Grid item xs={4}>
							<SmartField name="preferred_contact_method" />
						</Grid>
						<Grid item xs={12}>
							<SmartField name="requesting_for" />
						</Grid>
						<Grid item xs={4}>
							<SmartField name="existing_client" />
						</Grid>
						<Grid item xs={4}>
							<SmartField name="primary_record" />
						</Grid>
						<Grid item xs={4}>
							<SmartField name="compromised_safety" />
						</Grid>
					</Grid>
					<Grid container spacing={2} sx={{ width: "100%", padding: 4 }}>
						<Grid item xs={12}>
							<Typography variant="h5">Source</Typography>
						</Grid>
						<Grid item xs={12}>
							<SmartField name="self_identification" />
						</Grid>
						<Grid item xs={12}>
							<SmartField name="service_provider" />
						</Grid>
						<Grid item xs={4}>
							<SmartField name="contact_name" />
						</Grid>
						<Grid item xs={4}>
							<SmartField name="contact_email" />
						</Grid>
						<Grid item xs={4}>
							<SmartField name="contact_telephone" />
						</Grid>
						<Grid item xs={6}>
							<SmartField name="contact_department" />
						</Grid>
						<Grid item xs={6}>
							<SmartField name="referral_reason" />
						</Grid>
						<Grid item xs={12}>
							<SmartField name="source" />
						</Grid>
						<Grid item xs={12}>
							<SmartField name="other_source" />
						</Grid>
					</Grid>
					<Grid container spacing={2} sx={{ width: "100%", padding: 4 }}>
						<Grid item xs={12}>
							<Typography variant="h5">Contact</Typography>
						</Grid>
						<Grid item xs={12}>
							<SmartField name="address" />
						</Grid>
						<Grid item xs={4}>
							<SmartField name="apt" />
						</Grid>
						<Grid item xs={4}>
							<SmartField name="city" />
						</Grid>
						<Grid item xs={4}>
							<SmartField name="postal_code" />
						</Grid>
						<Grid item xs={12}>
							<SmartField name="no_address" />
						</Grid>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<Grid item xs={6}>
							<SmartField name="telephone" />
						</Grid>
						<Grid item xs={6}>
							<SmartField name="primary_telephone_type" />
						</Grid>
						<Grid item xs={6}>
							<SmartField name="secondary_telephone" />
						</Grid>
						<Grid item xs={6}>
							<SmartField name="secondary_telephone_type" />
						</Grid>
						<Grid item xs={6}>
							<SmartField name="additional_telephone" />
						</Grid>
						<Grid item xs={6}>
							<SmartField name="additional_telephone_type" />
						</Grid>
						<Grid item xs={6}>
							<SmartField name="request_information" />
						</Grid>
						<Grid item xs={6}>
							<SmartField name="agree_to_messages" />
						</Grid>
					</Grid>
					<Grid container spacing={2} sx={{ width: "100%", padding: 4 }}>
						<Grid item xs={12}>
							<Typography variant="h5">Demographics</Typography>
						</Grid>
						<Grid item xs={4}>
							<SmartField name="has_ohip" />
						</Grid>
						<Grid item xs={4}>
							<SmartField name="ohip_number" />
						</Grid>
						<Grid item xs={4}>
							<SmartField name="vc" />
						</Grid>
						<Grid item xs={4}>
							<SmartField name="citizenship" />
						</Grid>
						<Grid item xs={4}>
							<SmartField name="other_citizenship" />
						</Grid>
						<Grid item xs={4}>
							<SmartField name="country" />
						</Grid>
						<Grid item xs={6}>
							<SmartField name="time_in_canada" />
						</Grid>
						<Grid item xs={6}>
							<SmartField name="time_in_gta" />
						</Grid>
						<Grid item xs={6}>
							<SmartField name="gender" />
						</Grid>
						<Grid item xs={6}>
							<SmartField name="sexual_orientation" />
						</Grid>
						<Grid item xs={6}>
							<SmartField name="ethnicity" />
						</Grid>
						<Grid item xs={6}>
							<SmartField name="ethnicity_other" />
						</Grid>
						<Grid item xs={6}>
							<SmartField name="language" />
						</Grid>
						<Grid item xs={6}>
							<SmartField name="language_other" />
						</Grid>
						<Grid item xs={6}>
							<SmartField name="income" />
						</Grid>
						<Grid item xs={6}>
							<SmartField name="dependents" />
						</Grid>
					</Grid>
					<Grid container spacing={2} sx={{ width: "100%", padding: 4 }}>
						<Grid item xs={12}>
							<Typography variant="h5">Notes</Typography>
						</Grid>
						<Grid item xs={2}></Grid>
						<Grid item xs={8}>
							<ClientNotes />
						</Grid>
						<Grid item xs={2}></Grid>
					</Grid>
					<Grid container spacing={2} sx={{ width: "100%", padding: 4 }}>
						<Grid item xs={12}>
							<Typography variant="h5">Requests</Typography>
						</Grid>
						<Grid item xs={12}></Grid>
						<RequestsSummary />
						<Grid item xs={12}></Grid>
						<Grid item xs={12}>
							<Typography variant="h6">Autres services demandés</Typography>
							<SmartField name="other" />
						</Grid>
					</Grid>
					<ClientEmails />
				</Paper>
			</div>
		</Show>
	);
};

export default ClientShow;
