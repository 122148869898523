import { useState, useEffect } from 'react';
import { useDataProvider, Loading } from 'react-admin';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

const SourceTable = ({startDate, endDate}) => { 
    const dataProvider = useDataProvider();
    const [report, setReport] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    useEffect(() => {
        setLoading(true);
        const query = { id: 'source', meta: { startDate: startDate, endDate: endDate} };
        dataProvider.getOne('report', query )
            .then(({ data }) => {
                setReport(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
        }, [dataProvider, startDate, endDate]);

    if (loading) return <Loading />;
    if (error) return null;
    if (!report) return null;

    return(
        <TableContainer>
            <Table size="small">
                <TableBody>
                    { report.sources?.map((source) => {
                        let name = source.name === 'null' ? 'Not provided' : source.name;
                        name = name.charAt(0).toUpperCase() + name.substr(1).toLowerCase();
                        return (
                            <TableRow key={source.name}>
                                <TableCell component="th" scope="row">{source.name === 'null' ? 'Not provided' : name }</TableCell>
                                <TableCell align="right">{source.count}</TableCell>
                            </TableRow>
                        )
                    }
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}


export default SourceTable;