/**!SECTION
 * @file ClientInput.js
 * @version 1.0.0
 * @name ClientInput
 * @namespace Fields
 * @requires react-admin
 * @requires react
 * @requires @mui/material
 * @desc An Autocomplete field that can be used to search for and select one or more clients
 * 
 * @param {function} valueHandler - An optional function that can be called when the selected Clients changes
 * @param {function} optionsFilter - An optional function that cam be called to filter or transform the options returned from the API
 * 
 */

import { useState } from "react";
import { useGetList } from "react-admin";
import AutoComplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const ClientInput = ({ valueHandler = (updatedValue) => {}, optionsFilter = (options) => options, defaultValue = [] }) => {
	const [selectedClients, setSelectedClients] = useState(defaultValue);
	const [clientQuery, setClientQuery] = useState("");
	
	const filter = {};
	if( clientQuery ) filter.q = clientQuery;
	
	const { data: clients, isLoading: clientsLoading } = useGetList("clients", {filter});

	if (clientsLoading || !clients) return null;

	return (
		<AutoComplete
			name="client_input"
			multiple
			options={clients ? optionsFilter(clients) : []}
			getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
			value={selectedClients}
			noOptionsText={"Start typing to search for clients"}
			onInputChange={(event, newInputValue) => {
				setClientQuery(newInputValue);
			}}
			onChange={(event, newValue) => {
				setSelectedClients(newValue);
				valueHandler(newValue);
			}}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			renderInput={(params) => <TextField {...params} label="Clients" />}
		/>
	);
};

export default ClientInput;
