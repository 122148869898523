import * as React from "react";
import { Datagrid, List, TextField, DateField, EditButton } from 'react-admin';

export const EmailList = props => (
    <List sort={{ field: 'updatedAt', order: 'DESC' }} {...props}>
        <Datagrid>
            <DateField source="updatedAt" label="Updated" />
            <TextField source="name" label="Name" />
            <EditButton />
        </Datagrid>
    </List>
)

export default EmailList;