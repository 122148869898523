import { useState, useEffect } from 'react';
import { useDataProvider, Loading } from 'react-admin';
import { Table, TableBody, TableCell, TableContainer, TableRow, Collapse, IconButton } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const DemographicsTable = ({ startDate, endDate }) => {
    const dataProvider = useDataProvider();
    const [demographics, setDemographics] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    useEffect(() => {
        setLoading(true);
        const query = { id: 'demographics', meta: { startDate: startDate, endDate: endDate} };
        dataProvider.getOne('report', query )
            .then(({ data }) => {
                setDemographics(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
        }, [dataProvider, startDate, endDate]);

    if (loading) return <Loading />;
    if (error) return null;
    if (!demographics) return null;

    return(
        <TableContainer>
            <Table size="small">
                <TableBody>
                    <Row name="Gender" stats={demographics.gender} />
                    <Row name="Sexual Orientation" stats={demographics.sexual_orientation} />
                    <Row name="Income" stats={demographics.income} />
                    <Row name="Native Country" stats={demographics.native_country} />
                    <Row name="Languages Spoken" stats={demographics.language} />
                    <Row name="Citizenship" stats={demographics.citizenship} />
                    <Row name="Ethnicity" stats={demographics.ethnicity} />
                    <Row name="Homelessnes" stats={demographics.homeless} />
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const Row = (props) => {
    const { name, stats } = props;
    const [open, setOpen] = useState(false);
    return (
        <>
            <TableRow>
                <TableCell style={{width: '20px', fontWeight: 'bold', borderBottom: 0, paddingLeft: 0, paddingRight: 0}}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" style={{ width: 'calc(100% - 20px)', fontWeight: 'bold', borderBottom: 0}}>{name}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={2}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Table size="small" aria-label="services" style={{marginBottom: -1}}>
                            <TableBody>
                            {stats?.map((demographic) => (
                                <TableRow key={demographic.attribute}>
                                    <TableCell style={{width: '5px' }}></TableCell>
                                    <TableCell component="th" scope="row" style={{ width: 'calc(90% - 5px)' }}>{demographic.attribute === 'null' ? 'Not provided' : demographic.attribute }</TableCell>
                                    <TableCell align="center" style={{ width: '10%' }}>{demographic.count}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}


export default DemographicsTable;