import {
	useEditContext,
	Button,
	useCreate,
	useUpdate,
	useNotify,
} from "react-admin";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import ClientInput from "../Fields/ClientInput";
import CircularProgress from "@mui/material/CircularProgress";

const FamilyManage = ({ isOpen, onClose, currentFamily = [] }) => {
	const { record, isLoading } = useEditContext();
	const [familyMembers, setFamilyMembers] = useState([]);
	const [create, { createLoading }] = useCreate();
	const [update, { updateLoading }] = useUpdate();
	const notify = useNotify();

	if (isLoading || createLoading || updateLoading || !record)
		return <CircularProgress />;

	const handleCreate = () => {
		create(
			"families",
			{
				data: {
					clients: [record.id, ...familyMembers.map((client) => client.id)],
				},
			},
			{
				mutationMode: "pessimistic",
				onSuccess: () => {
					notify("Famille créée");
					onClose();
				},
				onError: (error) =>
					notify(`Error: ${error.message}`, { type: "warning" }),
			}
		);
	};

	const handleUpdate = () => {
		update(
			"families",
			{
				id: record.family,
				data: {
					clients: {
						set: familyMembers.map((client) => client.id),
					},
				},
			},
			{
				mutationMode: "pessimistic",
				onSuccess: () => {
					notify("Famille mise à jour");
					onClose();
				},
				onError: (error) =>
					notify(`Error: ${error.message}`, { type: "warning" }),
			}
		);
	};

	const handleChange = (value) => {
		setFamilyMembers(value);
	};

	const optionsFilter = (options) => {
		//Filter out clients with existing families, and return only the id, first_name and last_name
		return options
			.filter((client) => client.family.data === null)
			.map((client) => ({
				id: client.id,
				first_name: client.first_name,
				last_name: client.last_name,
			}));
	};

	return (
		<Dialog open={isOpen} onClose={onClose}>
			<DialogTitle>Manage Family</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{currentFamily.length > 0
						? "Ajouter des membres à la famille"
						: "Créer une nouvelle famille"}
				</DialogContentText>
				<ClientInput
					valueHandler={handleChange}
					optionsFilter={optionsFilter}
					defaultValue={currentFamily}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} label="Annuler" />
				{currentFamily.length > 0 ? (
					<Button onClick={handleUpdate} label="Ajouter" />
				) : (
					<Button onClick={handleCreate} label="Créer" />
				)}
			</DialogActions>
		</Dialog>
	);
};

export default FamilyManage;
