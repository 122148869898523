import { useState, useEffect } from 'react';
import { useDataProvider, Loading } from 'react-admin';
import { Table, TableHead, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

const AgentTable = ({startDate, endDate}) => { 
    const dataProvider = useDataProvider();
    const [report, setReport] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    useEffect(() => {
        setLoading(true);
        const query = { id: 'agent', meta: { startDate: startDate, endDate: endDate} };
        dataProvider.getOne('report', query )
            .then(({ data }) => {
                setReport(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
        }, [dataProvider, startDate, endDate]);

    if (loading) return <Loading />;
    if (error) return null;
    if (!report) return null;

    return(
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Agent</TableCell>
                        <TableCell>Requested</TableCell>
                        <TableCell>Referred</TableCell>
                        <TableCell>Acknowledged</TableCell>
                        <TableCell>Completed</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {report.agents.map((agent) => (
                    <TableRow key={agent.agent}>
                        <TableCell component="th" scope="row" style={{ fontWeight: 'bold'}}>{agent.agent}</TableCell>
                        <TableCell align="center">{agent.requested}</TableCell>
                        <TableCell align="center">{agent.referred}</TableCell>
                        <TableCell align="center">{agent.acknowledged}</TableCell>
                        <TableCell align="center">{agent.received}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}


export default AgentTable;