import { useGetList } from "react-admin";

const useOpenHAT = ({clientId}) => {
  const { data, isLoading } = useGetList("requests", {
		filter: { client: clientId, service: process.env.REACT_APP_HAT_ID, received_status: { $null: true } },
		pagination: { page: 1, perPage: 1 },
		meta: { source: "openHatrequests" },
	});
  return { data, isLoading };
}

export default useOpenHAT;